<template>
  <v-snackbar v-model="snackbar_active" timeout="-1" width="100%">
    <v-row>
      <v-col style="text-align: center; flex: max-content">
        <div>{{ current_termin_datetime }}</div>
        <div style="font-size: 0.8em">{{ current_mitarbeiter }}</div>
      </v-col>

      <v-col style="text-align: center">
        <v-icon> mdi-ray-start-arrow </v-icon>
      </v-col>

      <v-col style="text-align: center; flex: max-content">
        <div>{{ new_termin_datetime }}</div>
        <div style="font-size: 0.8em">{{ new_mitarbeiter }}</div>
      </v-col>
    </v-row>

    <template v-slot:action="{ attrs }">
      <v-btn color="red" text v-bind="attrs" @click="handleMoveTerminReturn">
        Zurück
      </v-btn>

      <v-btn color="white" text v-bind="attrs" @click="moveOpenDragConfirmation" :disabled="isSaveDisabled()">
        Ok
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script>
import moment from "moment";
import { mapActions } from "vuex";

const initialData = () => ({
  componentName: "TerminMove",
});

export default {
  name: "TerminMove",

  data() {
    return initialData();
  },
  computed: {
    current_termin_datetime() {
      let termin_lkp = this.$store.state.overlays.termin_move.termin;

      //TODO: fix this
      if (!termin_lkp.current) return "-";

      let timeStr = ""

      if ("start" in termin_lkp.current) {
        timeStr = moment(termin_lkp.current.start).format("DD.MM.YYYY HH:mm")
      }
      else {
        timeStr = moment(termin_lkp.current.startDate).format("DD.MM.YYYY") + " " + termin_lkp.current.startTime
      }

      return timeStr
    },

    new_termin_datetime() {
      let termin_lkp = this.$store.state.overlays.termin_move.termin;

      if (termin_lkp.new) {
        return termin_lkp.new
          ? moment(termin_lkp.new.start).format("DD.MM.YYYY HH:mm")
          : "";
      } else {
        return "Neuen Termin auswählen";
      }
    },

    current_mitarbeiter() {
      let termin_lkp = this.$store.state.overlays.termin_move.termin;
      if (!termin_lkp.current) return "-";

      const mitarbeiterId = termin_lkp.current.mitarbeiter;
      const mitarbeiterName = this.$store.getters["mitarbeiter/getMitarbeiterNamebyId"](
        mitarbeiterId
      );

      return mitarbeiterName;
    },

    new_mitarbeiter() {
      let termin_lkp = this.$store.state.overlays.termin_move.termin;
      return termin_lkp.new ? termin_lkp.new.mitarbeiter_str : "-";
    },

    snackbar_active: {
      get() {
        return this.$store.state.overlays.termin_move.active;
      },

      set(value) {
        this.$store.commit("overlays/updateTerminMoveSnackbar", value, {
          root: true,
        });
      },
    },
  },
  methods: {
    ...mapActions("overlays", ["moveOpenDragConfirmation", "handleMoveTerminReturn"]),

    isSaveDisabled() {
      let termin_lkp = this.$store.state.overlays.termin_move.termin;
      if (termin_lkp.new && termin_lkp.new.start && termin_lkp.new.mitarbeiter) {
        return false;
      }
      return true;
    },
  },
  watch: {},
};
</script>

<style>

</style>