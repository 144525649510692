var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1500px"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{attrs:{"light":""}},[_c('v-card-title',[_c('span',[_vm._v("Patienten")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeWindow}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-text-field',{staticClass:"pb-5",attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.openAddPatient(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-plus")]),_vm._v(" Neuer Patient ")],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.patienten,"items-per-page":10,"footer-props":{
              itemsPerPageText: 'Einträge pro Seite',
              itemsPerPageAllText: 'Alle',
            },"loading-text":"Daten werden geladen... Bitte warten","single-expand":true,"search":_vm.search},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" von "+_vm._s(props.itemsLength)+" ")]}},{key:"item.geburtsdatum",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.geburtsdatum))+" ")]}},{key:"item.unfalltag",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.unfalltag))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar-search ")])]}}],null,true)},[_c('span',[_vm._v("Termine")])])]}}])},[_c('template',{slot:"no-data"},[(_vm.patienten.length == 0 && _vm.search.length >= 2)?_c('div',[_vm._v(" Keinen passenden Patienten gefunden! ")]):_vm._e(),(_vm.patienten.length == 0 && _vm.search.length < 2)?_c('div',[_vm._v(" Patienten werden angezeigt sobald ein Suchbegriff eintippt wurde... ")]):_vm._e()])],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary-nav-btn",attrs:{"color":"primary","text":""},on:{"click":_vm.closeWindow}},[_vm._v("Zurück")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }