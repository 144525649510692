<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="800px">
      <v-card light>
        <v-card-title>
          <span>Neuer Patient</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="4">
                <v-select
                  v-model="patient.anrede"
                  :items="options.anrede"
                  label="Anrede"
                  prepend-icon="mdi-gender-transgender"
                  class="required"
                  :error-messages="anredeErrors"
                  @input="v$.patient.anrede.$touch()"
                  @blur="v$.patient.anrede.$touch()"
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.titel"
                  label="Titel"
                  prepend-icon="mdi-school"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" class="mt-0">
              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.vorname"
                  label="Vorname"
                  prepend-icon="mdi-card-account-details-outline"
                  class="required"
                  @input="v$.patient.vorname.$touch()"
                  @blur="v$.patient.vorname.$touch()"
                  :error-messages="vornameErrors"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.name"
                  label="Nachname"
                  prepend-icon="mdi-card-account-details"
                  class="required"
                  :error-messages="nameErrors"
                  @input="v$.patient.name.$touch()"
                  @blur="v$.patient.name.$touch()"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="4">
                <v-menu
                  v-model="menu.geburtsdatum"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      ref="geburtsdatum"
                      label="Geburtsdatum"
                      prepend-icon="mdi-cake-variant"
                      v-model="selections.geburtsdatum"
                      v-on="on"
                      class="required"
                      :error-messages="geburtsdatumErrors"
                      @input="handleInputDateText('geburtsdatum')"
                      @focus="handleFocusDateText('geburtsdatum')"
                      placeholder="TT.MM.JJJJ"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="geburtsdatum_date_picker"
                    v-model="patient.geburtsdatum"
                    :active-picker.sync="geburtsdatum_picker"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1910-01-01"
                    @input="handleInputDatePicker('geburtsdatum')"
                    first-day-of-week="1"
                    locale="de-de"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-divider class="py-2 mt-4"></v-divider>

            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.strasse"
                  ref="strasse"
                  label="Strasse"
                  prepend-icon="mdi-home"
                  class="required"
                  :error-messages="strasseErrors"
                  @input="v$.patient.strasse.$touch()"
                  @blur="v$.patient.strasse.$touch()"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.plz"
                  label="PLZ"
                  prepend-icon="mdi-counter"
                  v-mask="[...masks.plz]"
                  class="required"
                  :error-messages="plzErrors"
                  @input="v$.patient.plz.$touch()"
                  @blur="v$.patient.plz.$touch()"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.ort"
                  label="Ort"
                  prepend-icon="mdi-home-city"
                  class="required"
                  :error-messages="ortErrors"
                  @input="v$.patient.ort.$touch()"
                  @blur="v$.patient.ort.$touch()"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" class="mt-0">
              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.telefon_priv"
                  label="Telefon - Privat"
                  prepend-icon="mdi-phone"
                  type="tel"
                  v-mask="[...masks.telephone]"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.telefon_mob"
                  label="Telefon - Mobil"
                  type="tel"
                  prepend-icon="mdi-cellphone"
                  v-mask="[...masks.telephone]"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="4">
                <v-text-field
                  v-model="patient.telefon_arb"
                  label="Telefon - Arbeit"
                  type="tel"
                  prepend-icon="mdi-deskphone"
                  v-mask="[...masks.telephone]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="py-2 mt-4"></v-divider>

            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="6">
                <v-autocomplete
                  label="Mitarbeiter"
                  v-model="patient.mitarbeiter"
                  :items="mitarbeiter"
                  placeholder="Mitarbeiter auswählen"
                  prepend-icon="mdi-doctor"
                  :item-text="(item) => item.name + ', ' + item.vorname"
                  :item-value="(item) => item.id"
                  hide-no-data
                  class="required"
                  :error-messages="mitarbeiterErrors"
                  @input="v$.patient.mitarbeiter.$touch()"
                  @blur="v$.patient.mitarbeiter.$touch()"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="center" class="mt-0">
              <v-col class="d-flex" cols="12" sm="12">
                <v-textarea
                  v-model="patient.info"
                  label="Zusatzinformationen"
                  prepend-icon="mdi-information"
                  rows="1"
                  :auto-grow="true"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-divider class="py-2 mt-4"></v-divider>

            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  v-model="patient.kt_typ"
                  :items="options.kt_typ"
                  :item-text="(item) => item[1]"
                  :item-value="(item) => item[0]"
                  label="Kostenträger-Typ"
                  prepend-icon="mdi-domain"
                  class="required"
                  @change="resetKTData"
                  :error-messages="kttypErrors"
                  @input="v$.patient.kt_typ.$touch()"
                  @blur="v$.patient.kt_typ.$touch()"
                ></v-select>
              </v-col>
            </v-row>

            <div v-if="patient.kt_typ == 'BG'">
              <v-row align="center">
                <v-col class="d-flex pt-0" cols="12">
                  <v-autocomplete
                    label="Kostenträger"
                    class="required"
                    v-model="patient.kostentraeger"
                    :loading="kt_search_loading"
                    :search-input.sync="kt_search_term"
                    :items="kostentraeger"
                    placeholder="Tippen um einen Kostenträger zu suchen"
                    prepend-icon="mdi-office-building"
                    :item-text="kt_text"
                    no-data-text="Keine passenden Kostenträger gefunden"
                    item-value="id"
                    return-object
                    :error-messages="kostentraegerErrors"
                    @input="v$.patient.kostentraeger.$touch()"
                    @blur="v$.patient.kostentraeger.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="center" class="mt-0">
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menu.unfalltag"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="unfalltag"
                        label="Unfalltag"
                        prepend-icon="mdi-calendar"
                        v-model="selections.unfalltag"
                        v-on="on"
                        class="required"
                        :error-messages="unfalltagErrors"
                        @input="handleInputDateText('unfalltag')"
                        @focus="handleFocusDateText('unfalltag')"
                        placeholder="TT.MM.JJJJ"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="unfalltag_date_picker"
                      v-model="patient.unfalltag"
                      :active-picker.sync="unfalltag_picker"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1910-01-01"
                      @input="handleInputDatePicker('unfalltag')"
                      first-day-of-week="1"
                      locale="de-de"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>

            <div v-if="patient.kt_typ == 'PP'">
              <v-row align="center">
                <v-col class="d-flex pt-0" cols="12" sm="6">
                  <v-checkbox
                    label="Abweichende Rechnungsadresse?"
                    v-model="patient.alt_rechnungsadresse"
                    @change="clearAlternativeRA"
                    :hide-details="true"
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <div v-if="patient.alt_rechnungsadresse">
                <v-row align="center">
                  <v-col class="d-flex" cols="12" sm="4">
                    <v-select
                      v-model="patient.alt_anrede"
                      :items="options.anrede"
                      label="Anrede"
                      prepend-icon="mdi-gender-male-female"
                      class="required"
                      :error-messages="alt_anredeErrors"
                      @input="v$.patient.alt_anrede.$touch()"
                      @blur="v$.patient.alt_anrede.$touch()"
                    ></v-select>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="4">
                    <v-text-field
                      v-model="patient.alt_titel"
                      label="Titel"
                      prepend-icon="mdi-school"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row align="center" class="mt-0">
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      v-model="patient.alt_vorname"
                      label="Vorname"
                      prepend-icon="mdi-card-account-details-outline"
                      class="required"
                      :error-messages="alt_vornameErrors"
                      @input="v$.patient.alt_vorname.$touch()"
                      @blur="v$.patient.alt_vorname.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      v-model="patient.alt_name"
                      label="Nachname"
                      prepend-icon="mdi-card-account-details"
                      class="required"
                      :error-messages="alt_nameErrors"
                      @input="v$.patient.alt_name.$touch()"
                      @blur="v$.patient.alt_name.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row align="center" class="mt-0">
                  <v-col class="d-flex" cols="12" sm="4">
                    <v-text-field
                      v-model="patient.alt_strasse"
                      label="Strasse"
                      prepend-icon="mdi-home"
                      class="required"
                      :error-messages="alt_strasseErrors"
                      @input="v$.patient.alt_strasse.$touch()"
                      @blur="v$.patient.alt_strasse.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="4">
                    <v-text-field
                      v-model="patient.alt_plz"
                      label="PLZ"
                      prepend-icon="mdi-counter"
                      v-mask="[...masks.plz]"
                      class="required"
                      :error-messages="alt_plzErrors"
                      @input="v$.patient.alt_plz.$touch()"
                      @blur="v$.patient.alt_plz.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="4">
                    <v-text-field
                      v-model="patient.alt_ort"
                      label="Ort"
                      prepend-icon="mdi-home-city"
                      class="required"
                      :error-messages="alt_ortErrors"
                      @input="v$.patient.alt_ort.$touch()"
                      @blur="v$.patient.alt_ort.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div v-if="['KK', 'PBeaKK', 'S'].includes(patient.kt_typ)">
              <v-row align="center">
                <v-col class="d-flex pt-0" cols="12">
                  <v-autocomplete
                    label="Kostenträger"
                    class="required"
                    v-model="patient.kostentraeger"
                    :loading="kt_search_loading"
                    :search-input.sync="kt_search_term"
                    :items="kostentraeger"
                    placeholder="Tippen um einen Kostenträger zu suchen"
                    prepend-icon="mdi-office-building"
                    :item-text="kt_text"
                    no-data-text="Keine passenden Kostenträger gefunden"
                    item-value="id"
                    return-object
                    :error-messages="kostentraegerErrors"
                    @input="v$.patient.kostentraeger.$touch()"
                    @blur="v$.patient.kostentraeger.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="center" class="mt-0">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    v-model="patient.vers_status"
                    :items="versicherungsstatus"
                    class="required"
                    label="Versicherungsstatus"
                    prepend-icon="mdi-alpha-s-circle"
                    :item-text="(item) => item.bezeichnung"
                    :item-value="(item) => item.id"
                    :error-messages="versstatusErrors"
                    @input="v$.patient.vers_status.$touch()"
                    @blur="v$.patient.vers_status.$touch()"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    v-model="patient.vers_nummer"
                    label="Versicherungsnummer"
                    class="required"
                    prepend-icon="mdi-alpha-n-circle"
                    v-mask="customTokens"
                    :error-messages="versnummerErrors"
                    @input="v$.patient.vers_nummer.$touch()"
                    @blur="v$.patient.vers_nummer.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                align="center"
                v-show="patient.kt_typ == 'KK'"
                class="mt-0"
              >
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menu.zuzahlung_v"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="zzVon"
                        label="Zuzahlungsbefreiung (von)"
                        prepend-icon="mdi-calendar"
                        v-model="selections.zuzahlung_v"
                        v-on="on"
                        :error-messages="zzVonErrors"
                        @input="handleInputDateText('zuzahlung_v')"
                        @focus="handleFocusDateText('zuzahlung_v')"
                        clearable
                        @click:clear="patient.zuzahlung_v = null"
                        placeholder="TT.MM.JJJJ"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="zzVon_date_picker"
                      v-model="patient.zuzahlung_v"
                      :active-picker.sync="zzVon_picker"
                      @input="handleInputDatePicker('zuzahlung_v')"
                      locale="de-de"
                      first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menu.zuzahlung_b"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="zzBis"
                        label="Zuzahlungsbefreiung (bis)"
                        prepend-icon="mdi-calendar"
                        v-model="selections.zuzahlung_b"
                        v-on="on"
                        :error-messages="zzBisErrors"
                        @input="handleInputDateText('zuzahlung_b')"
                        @focus="handleFocusDateText('zuzahlung_b')"
                        clearable
                        @click:clear="patient.zuzahlung_b = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="zzBis_date_picker"
                      v-model="patient.zuzahlung_b"
                      :active-picker.sync="zzBis_picker"
                      @input="handleInputDatePicker('zuzahlung_b')"
                      locale="de-de"
                      first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeWindow"
            :disabled="buttons_disabled"
            >Zurück</v-btn
          >
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="create_Patient"
            :disabled="buttons_disabled"
            :loading="buttons_disabled"
          >
            Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="errorDialog"
      persistent
      max-width="520px"
      transition="dialog-bottom-transition"
    >
      <v-card v-if="requestErrors.length != 0">
        <v-card-title style="background-color: #f5f5f5">
          <v-spacer />
          <v-icon left> mdi-alert-circle </v-icon>
          <span v-if="requestErrors[0].id == 'VersnrExists'"
            >Ungültige Versicherungsnummer</span
          >
          <span
            v-else-if="
              ['PatientExists', 'BGPatientExists'].includes(requestErrors[0].id)
            "
            >Patient existiert bereits</span
          >
          <span v-else-if="requestErrors[0].id == 'CombinationError'"
            >Ungültige Kostenträger Kombination</span
          >
          <v-spacer />
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div v-if="requestErrors[0].id == 'VersnrExists'">
                  Die angegebene Versicherungsnummer wird bereits durch
                  folgenden Patienten verwendet:
                </div>

                <div
                  v-else-if="
                    ['PatientExists', 'BGPatientExists'].includes(
                      requestErrors[0].id
                    )
                  "
                >
                  <span
                    >Dieser Patient existiert bereits für den ausgewählten
                    Kostenträger-Typ.</span
                  >
                  <span v-if="nacherfassungItem"
                    ><br />
                    Wenn Sie fortfahren, wird der folgende Patient
                    wiederverwendet:</span
                  >
                </div>

                <div v-else-if="requestErrors[0].id == 'CombinationError'">
                  {{ requestErrors[0].msg }}
                </div>
              </v-col>

              <v-col cols="12">
                <v-card class="mx-auto" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div
                        class="text-overline mb-4"
                        v-if="requestErrors[0].related[0].vers_nummer"
                      >
                        Vers.-Nr.: {{ requestErrors[0].related[0].vers_nummer }}
                      </div>

                      <div
                        class="text-overline mb-4"
                        v-if="requestErrors[0].related[0].unfalltag"
                      >
                        Unfalltag:
                        {{ requestErrors[0].related[0].unfalltag | formatDate }}
                      </div>

                      <v-list-item-title class="text-h5 mb-1">
                        {{ requestErrors[0].related[0].name }},
                        {{ requestErrors[0].related[0].vorname }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >geb.
                        {{
                          requestErrors[0].related[0].geburtsdatum | formatDate
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-avatar tile size="80" color="primary">
                      <span class="white--text text-h6">{{
                        requestErrors[0].related[0].kt_typ
                      }}</span>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeErrorDialog"
            :disabled="savingGroupData"
          >
            <span>Zurück</span>
          </v-btn>
          <v-btn
            v-if="
              ['PatientExists', 'BGPatientExists'].includes(
                requestErrors[0].id
              ) && nacherfassungItem
            "
            color="primary"
            class="primary-nav-btn"
            text
            @click="updateGroupData"
            :disabled="savingGroupData"
            :loading="savingGroupData"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapMutations, mapActions } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf, minLength } from "@vuelidate/validators";

const defaultMenuData = () => ({
  geburtsdatum: false,
  zuzahlung_v: false,
  zuzahlung_b: false,
  unfalltag: false,
});

const defaultSelectionsData = () => ({
  geburtsdatum: null,
  zuzahlung_b: null,
  zuzahlung_v: null,
  unfalltag: null,
});

const initialData = () => ({
  geburtsdatum_picker: "YEAR",
  unfalltag_picker: "YEAR",
  zzVon_picker: "DATE",
  zzBis_picker: "DATE",
  kt_search_loading: false,
  kt_search_term: "",

  buttons_disabled: false,

  errorDialog: false,
  requestErrors: [],
  savingGroupData: false,

  masks: {
    telephone: ["###############"],
    plz: ["#####"],
  },

  menu: defaultMenuData(),

  selections: defaultSelectionsData(),
  options: {
    anrede: ["Frau", "Herr", "Neutral"],
    kt_typ: [
      ["BG", "Berufsgenossenschaft"],
      ["KK", "Krankenkasse"],
      ["PBeaKK", "Postbeamtenkrankenkasse"],
      ["PP", "Privat"],
      ["S", "Sonstige (Polizei, Verwaltungen, etc.)"],
    ],
  },

  patient: {
    kunde: "",
    anrede: "",
    titel: "",
    vorname: "",
    name: "",
    geburtsdatum: null,
    strasse: "",
    plz: "",
    ort: "",

    telefon_priv: "",
    telefon_mob: "",
    telefon_abr: "",

    mitarbeiter: null,
    kostentraeger: "",

    unfalltag: null,
    vers_status: "",
    vers_nummer: "",

    zuzahlung_v: null,
    zuzahlung_b: null,

    alt_rechnungsadresse: false,
    alt_titel: "",
    alt_anrede: "",
    alt_name: "",
    alt_vorname: "",
    alt_plz: "",
    alt_ort: "",
    alt_strasse: "",
  },

  customTokens: {
    mask: "FFFFFFFFFFFFFFFFFF",
    tokens: {
      F: {
        pattern: /[0-9a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
    },
  },
});

function zzisBefore(value) {
  let rv = true;
  if (value && this.patient.zuzahlung_b) {
    if (moment(value).isAfter(this.patient.zuzahlung_b)) {
      rv = false;
    }
  }
  return rv;
}

function zzisAfter(value) {
  let rv = true;
  if (value && this.patient.zuzahlung_v) {
    if (moment(value).isBefore(this.patient.zuzahlung_v)) {
      rv = false;
    }
  }
  return rv;
}

function zzBisPair(value) {
  if (!value && this.patient.zuzahlung_b) {
    return false;
  }
  return true;
}

function zzVonPair(value) {
  if (!value && this.patient.zuzahlung_v) {
    return false;
  }
  return true;
}

export default {
  name: "PatientAdd",

  setup() {
    return { v$: useVuelidate() };
  },

  validations: {
    patient: {
      anrede: {
        required,
      },

      vorname: {
        required,
      },

      name: {
        required,
      },

      geburtsdatum: {
        required,
      },

      strasse: {
        required,
      },

      plz: {
        required,
        minLength: minLength(5),
      },

      ort: {
        required,
      },

      mitarbeiter: {
        required,
      },

      kt_typ: {
        required,
      },

      kostentraeger: {
        required: requiredIf(function() {
          return this.patient.kt_typ != "PP";
        }),
      },

      unfalltag: {
        required: requiredIf(function() {
          return this.patient.kt_typ == "BG";
        }),
      },

      vers_status: {
        required: requiredIf(function() {
          return this.patient.kt_typ != "PP" && this.patient.kt_typ != "BG";
        }),
      },

      vers_nummer: {
        required: requiredIf(function() {
          return this.patient.kt_typ != "PP" && this.patient.kt_typ != "BG";
        }),
      },

      zuzahlung_v: { zzisBefore, zzBisPair },
      zuzahlung_b: { zzisAfter, zzVonPair },

      alt_anrede: {
        required: requiredIf(function() {
          return this.alt_rechnungsadresse;
        }),
      },
      alt_vorname: {
        required: requiredIf(function() {
          return this.alt_rechnungsadresse;
        }),
      },
      alt_name: {
        required: requiredIf(function() {
          return this.alt_rechnungsadresse;
        }),
      },
      alt_strasse: {
        required: requiredIf(function() {
          return this.alt_rechnungsadresse;
        }),
      },
      alt_ort: {
        required: requiredIf(function() {
          return this.alt_rechnungsadresse;
        }),
      },
      alt_plz: {
        required: requiredIf(function() {
          return this.alt_rechnungsadresse;
        }),
        minLength: minLength(5),
      },
    },
  },

  data: function() {
    return initialData();
  },

  filters: {
    formatDate(value) {
      return value ? moment(String(value)).format("DD.MM.YYYY") : "";
    },
  },

  computed: {
    active() {
      return this.$store.getters["overlays/patientAddActive"];
    },

    nacherfassungItem() {
      return this.$store.getters["overlays/patientNacherfassungItem"];
    },

    formatZZVon() {
      return this.patient.zuzahlung_v
        ? moment(this.patient.zuzahlung_v).format("DD.MM.YYYY")
        : "";
    },

    formatZZBis() {
      return this.patient.zuzahlung_b
        ? moment(this.patient.zuzahlung_b).format("DD.MM.YYYY")
        : "";
    },

    patientData() {
      return this.patient;
    },

    kostentraeger() {
      return this.$store.getters["kostentraeger/getKostentraegerWithKtTyp"](
        this.patient.kt_typ
      );
    },

    versicherungsstatus() {
      return this.$store.getters["versicherungsstatus/getVersicherungsStatus"];
    },

    mitarbeiter() {
      return this.$store.getters["mitarbeiter/getMitarbeiter"];
    },

    // error messages

    anredeErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.anrede;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Anrede ist leer!");
      }

      return errors;
    },

    vornameErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.vorname;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Vorname ist leer!");
      }

      return errors;
    },

    nameErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.name;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Nachname ist leer!");
      }

      return errors;
    },

    geburtsdatumErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.geburtsdatum;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Geburtsdatum ist leer!");
      }

      return errors;
    },

    strasseErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.strasse;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Strasse ist leer!");
      }

      return errors;
    },

    plzErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.plz;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("PLZ ist leer!");
      }

      return errors;
    },

    ortErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.ort;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Ort ist leer!");
      }

      return errors;
    },

    mitarbeiterErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.mitarbeiter;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Mitarbeiter ist leer!");
      }

      return errors;
    },

    kttypErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.kt_typ;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Kostenträger-Typ ist leer!");
      }

      return errors;
    },

    kostentraegerErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.kostentraeger;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Kostenträger ist leer!");
      }

      return errors;
    },

    unfalltagErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.unfalltag;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Unfalltag ist leer!");
      }

      return errors;
    },

    versstatusErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.vers_status;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Versicherungsstatus ist leer!");
      }

      return errors;
    },

    versnummerErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.vers_nummer;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Versicherungsnummer ist leer!");
      }

      return errors;
    },

    alt_anredeErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.alt_anrede;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Anrede ist leer!");
      }

      return errors;
    },

    alt_vornameErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.alt_vorname;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Vorname ist leer!");
      }

      return errors;
    },

    alt_nameErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.alt_name;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Name ist leer!");
      }

      return errors;
    },

    alt_strasseErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.alt_strasse;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Strasse ist leer!");
      }

      return errors;
    },

    alt_plzErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.alt_plz;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("PLZ ist leer!");
      }

      return errors;
    },

    alt_ortErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.alt_ort;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Ort ist leer!");
      }

      return errors;
    },

    zzVonErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.zuzahlung_v;
        entry.zzisBefore.$invalid &&
          errors.push("Zuzahlung-Von liegt nach Zuzahlung-Bis!");

        entry.zzBisPair.$invalid &&
          errors.push("Beide Daten müssen angegeben werden!");
      }

      return errors;
    },

    zzBisErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient.zuzahlung_b;
        entry.zzisAfter.$invalid &&
          errors.push("Zuzahlung-Bis liegt vor Zuzahlung-Von!");
        entry.zzVonPair.$invalid &&
          errors.push("Beide Daten müssen angegeben werden!");
      }

      return errors;
    },
  },
  methods: {
    ...mapMutations("overlays", ["closeAddPatient"]),
    ...mapMutations("kostentraeger", ["resetKostentraeger"]),
    ...mapActions("kostentraeger", ["Search"]),
    ...mapActions("patienten", ["CreatePatient"]),
    ...mapActions("nacherfassung", ["createNacherfassungPatient"]),
    ...mapActions("termine", ["updateAppointmentGroup"]),

    handleInputDateText(name) {
      if (this.selections[name]) {
        let m = moment(this.selections[name], "DD.MM.YYYY", true);
        if (m.isValid()) {
          this.patient[name] = m.format("YYYY-MM-DD");

          setTimeout(() => {
            this.menu[name] = false;
          }, 200);
        } else {
          this.patient[name] = null;
        }
      } else {
        this.patient[name] = null;
      }

      // touch element
      this.v$.patient[name].$touch();
    },

    handleFocusDateText(name) {
      // Filter all menu names except the current one
      var menu_names = Object.keys(this.menu).filter((e) => e !== name);

      setTimeout(() => {
        if (!this.menu[name]) {
          this.menu[name] = true;
        }
        for (const n of menu_names) {
          this.menu[n] = false;
        }
      }, 200);
    },

    handleInputDatePicker(name) {
      let focus_lkp = {
        geburtsdatum: ["strasse"],
      };

      // set selection
      this.selections[name] = moment(this.patient[name]).format("DD.MM.YYYY");

      // close picker
      this.menu[name] = false;

      // focus next element
      let resolution_order = focus_lkp[name];
      for (var element in resolution_order) {
        if (this.$refs[resolution_order[element]]) {
          let next = resolution_order[element];
          this.$refs[next].focus();
          break;
        }
      }
    },

    clearAlternativeRA() {
      this.patient.alt_titel = "";
      this.patient.alt_anrede = "";
      this.patient.alt_name = "";
      this.patient.alt_vorname = "";
      this.patient.alt_plz = "";
      this.patient.alt_ort = "";
      this.patient.alt_strasse = "";

      this.v$.patient.alt_anrede.$reset();
      this.v$.patient.alt_name.$reset();
      this.v$.patient.alt_vorname.$reset();
      this.v$.patient.alt_plz.$reset();
      this.v$.patient.alt_ort.$reset();
      this.v$.patient.alt_strasse.$reset();
    },

    saveUnfall(date) {
      this.$refs.unfalltag_menu.save(date);
    },

    kt_text: (item) => item.ik_num + " - " + item.name_1,

    resetKTData() {
      this.clearAlternativeRA();
      this.patient.alt_rechnungsadresse = false;

      this.v$.patient.kostentraeger.$reset();
      this.patient.kostentraeger = "";

      this.v$.patient.vers_status.$reset();
      this.patient.vers_status = "";

      this.v$.patient.vers_nummer.$reset();
      this.patient.vers_nummer = "";

      this.v$.patient.unfalltag.$reset();
      this.patient.unfalltag = null;
    },

    closeErrorDialog() {
      this.errorDialog = false;
      this.savingGroupData = false;
      this.responseErrors = [];
    },

    closeWindow() {
      // reset errors
      this.v$.$reset();

      // reset menu and selections
      this.selections = defaultSelectionsData();
      this.menu = defaultMenuData();

      Object.assign(this.$data, initialData());

      this.resetKostentraeger();
      this.closeAddPatient();
    },

    async updateGroupData() {
      let data = {
        id: this.nacherfassungItem.id,
        patient: this.requestErrors[0].related[0].id,
      };

      this.savingGroupData = true;

      await this.updateAppointmentGroup(data).then(() => {
        this.closeErrorDialog();
        this.closeWindow();
      });
    },

    async create_Patient() {
      this.v$.$touch();
      if (this.v$.$errors.length == 0) {
        this.buttons_disabled = true;
        this.patient.kunde = this.$store.getters["auth/currentUser"].user_id;

        let temp_kt = this.patient.kostentraeger;
        if (this.patient.kostentraeger) {
          this.patient.kostentraeger = this.patient.kostentraeger.id;
        }

        // new patient
        if (!this.nacherfassungItem) {
          await this.CreatePatient(this.patient)
            .then(() => {
              this.closeWindow();
            })
            .catch((error) => {
              this.patient.kostentraeger = temp_kt;
              this.requestErrors = error.response.data.errors;
              this.errorDialog = true;
            });
        }
        // nacherfassung patient
        else {
          let data = {
            group_id: this.nacherfassungItem.id,
            patient: this.patient,
          };

          await this.createNacherfassungPatient(data)
            .then(() => {
              this.closeWindow();
            })
            .catch((error) => {
              this.patient.kostentraeger = temp_kt;
              this.requestErrors = error.response.data.errors;
              this.errorDialog = true;
            });
        }
      }

      this.buttons_disabled = false;
    },
  },
  watch: {
    "menu.geburtsdatum": function(val) {
      if (val) {
        val && setTimeout(() => (this.geburtsdatum_picker = "YEAR"));
      }
    },

    "menu.unfalltag": function(val) {
      if (val) {
        val && setTimeout(() => (this.unfalltag_picker = "YEAR"));
      }
    },

    active(val) {
      // set name, vorname, geburtsdatum if PatientAdd is opened via Nacherfassung
      if (val) {
        if (this.nacherfassungItem) {
          let item = this.nacherfassungItem;

          this.patient.id = item.patient.id;
          this.patient.name = item.patient.name;
          this.patient.vorname = item.patient.vorname;

          if (item.patient.geburtsdatum) {
            this.patient.geburtsdatum = item.patient.geburtsdatum;
            this.selections.geburtsdatum = moment(
              item.patient.geburtsdatum
            ).format("DD.MM.YYYY");
          }

          this.patient.telefon_priv = item.patient.telefon_priv;
          this.patient.telefon_mob = item.patient.telefon_mob;
          this.patient.telefon_arb = item.patient.telefon_arb;

          if (item.patient.mitarbeiter) {
            this.patient.mitarbeiter = item.patient.mitarbeiter;
          }
        } else {
          if ("geburtsdatum_date_picker" in this.$refs) {
            this.$refs.geburtsdatum_date_picker.tableDate = new Date()
              .toISOString()
              .substr(0, 7);
          }
        }
      }
    },

    async kt_search_term(val) {
      if (val) {
        let key = null;
        if (this.patient.kostentraeger) {
          key =
            this.patient.kostentraeger.ik_num +
            " - " +
            this.patient.kostentraeger.name_1;
        }

        if (val && val.length >= 3 && val != key) {
          this.loading = true;

          const params = {
            term: val,
            kt_typ: this.patient.kt_typ,
          };

          await this.Search(params);
          this.loading = false;
        }
      }
    },
  },

  mounted() {},
};
</script>

<style></style>
