import Vue from "vue";
import VueCompositionAPI from '@vue/composition-api'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import moment from "moment";
import VueMeta from "vue-meta";
import VueTheMask from "vue-the-mask";

import "@/assets/css/global.css"

let $eventBus = new Vue();
Vue.prototype.$eventBus = $eventBus;

Vue.config.productionTip = false;

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.withCredentials = true

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;

axios.interceptors.request.use(
  function(request) {
    // only emit requestEvent on:
    // 1. get/post
    // 2. !token-refresh/
    if (!["login/", "token-refresh/"].includes(request.url) && ["get", "post"].includes(request.method)) {
      $eventBus.$emit("requestEvent");
    }

    return request;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

Vue.use(VueCompositionAPI)
Vue.use(VueMeta);
Vue.use(VueTheMask);

new Vue({
  router,
  store,
  vuetify,
  moment,
  render: (h) => h(App),
}).$mount("#app");
