<template>
  <v-row justify="center">
    <v-dialog
      v-model="active"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <v-spacer />
          <v-icon left> mdi-message-alert </v-icon>
          <span>Ungültige Heilmittel gefunden</span>
          <v-spacer />
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container>
            <v-row style="text-align: center">
              <v-col cols="12">
                <div class="my-4">
                  <p>
                    Das erfasste Rezept enthält Termine mit Heilmitteln, die in
                    dieser Kombination ungültig sind. Die Heilmittel können über die
                    Nacherfassung oder spätestens in der Medisum-App korrigiert
                    werden.
                  </p>

                  <p>
                    <strong>Hinweis:</strong> Für Rezepte ohne gültige Heilmittel
                    können keine <strong>Quittungen</strong> oder
                    <strong>Rechnungen</strong> erzeugt werden.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-nav-btn" color="primary" @click="close"
            >Verstanden</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const initialData = () => ({});

export default {
  name: "UnmatchedTarifeNotification",

  data() {
    return initialData();
  },

  computed: {
    active() {
      return this.$store.getters["overlays/unmatchedTarifeNotificationActive"];
    },
  },

  methods: {
    close() {
      this.$store.dispatch("overlays/closeUnmatchedTarifeNotification");
    },
  },

  watch: {},

  mounted() {},
};
</script>

<style scoped></style>
