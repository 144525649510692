import axios from "axios";

const state = {
};

const getters = {
};

const actions = {
  async createNacherfassungPatient({ dispatch, rootGetters }, data) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    const response = await axios.post("nacherfassung/patient/", data, config);

    dispatch("termine/getAppointmentData", null, { root: true });

    return response;
  },

  async createNacherfassungPrescription({ dispatch, rootGetters }, data) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      const response = await axios.post("nacherfassung/rezept/", data, config);

      // Update appointments in state
      await dispatch("termine/getAppointmentData", null, { root: true });

      return response.data;
    } catch (error) {
      throw "Some error occured while creating nacherfassung:" + error;
    }
  },

  async queryNacherfassung({ rootGetters }, params = {}) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
      params: params,
    };

    try {
      const response = await axios.get("nacherfassung/", config);
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  },

  async getNacherfassungTarife({ rootGetters }, groupId) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      const response = await axios.get(`nacherfassung/tarife/${groupId}/`, config);
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  },

  async updateNacherfassungTarife({ rootGetters }, data) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    try {
      await axios.post(`nacherfassung/tarife/${data.groupId}/`, data.entries, config);
      return 
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }

};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
