import axios from "axios";

const getDefaultState = () => {
  return {
    settings: {},
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getPrivatInfoText: (state) => {
    return state.settings.privat_info_text;
  },

  getLogoUrl: (state) => {
    return "logo_url" in state.settings ? state.settings.logo_url : null;
  },
};

const actions = {
  async GetSettingsData({ commit, rootGetters }) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    return axios
      .get("settings/", config)
      .then((res) => {
        commit("setSettingsData", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async UploadLogo({ commit, rootGetters }, image) {
    let config = {
      headers: rootGetters["auth/authHeaders"],
    };

    let data = new FormData();
    data.append("file", image);

    return axios
      .put("upload/logo/", data, config)
      .then((res) => {
        commit("setSettingsData", res.data);

        commit("setLogoUrl", res.data.logoUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  setSettingsData(state, data) {
    state.settings = data;
  },

  setLogoUrl(state, url) {
    state.settings.logo_url = url;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
