<template>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" class="custom-icon">
    <g>
        <g id="Layer_1">
        <path d="M17.8,16.3c3,.6,5.2,2,5.2,3.7v2H7v-2c0-1.7,2.1-3.2,5.2-3.7l2.8,4.7,2.8-4.7M11,8h8v2c0,2.2-1.8,4-4,4s-4-1.8-4-4v-2M11,7l.4-4.1c0-.5.5-.9,1-.9h5.2c.5,0,.9.4,1,.9l.4,4.1h-8M15,3h-1v1h-1v1h1v1h1v-1h1v-1h-1v-1Z"/>
        <polyline points="6 10 6 7 4 7 4 10 1 10 1 12 4 12 4 15 6 15 6 12 9 12 9 10"/>
        </g>
    </g>
    </svg>
</template>

<script>
export default {
    name: "DoctorAddIcon"
}
</script>

<style>
.custom-icon {
  fill: currentColor;
}
</style>