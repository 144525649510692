<template>
  <v-row justify="center">
    <v-dialog v-model="active" v-if="active" persistent max-width="800px">
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Terminübersicht</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-alert border="left" color="primary" outlined type="info"
              v-if="appointmentGroup.gruppen_typ == 'Behandlung' && !appointmentGroup.patient_selected">
              <v-row align="center">
                <v-col class="grow"> Patient wurde noch nicht erfasst!</v-col>
                <v-col class="shrink">
                  <v-btn color="secondary" x-small @click="patientErfassen()">Erfassen</v-btn>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert border="left" color="primary" outlined type="info"
              v-if="appointmentGroup.gruppen_typ == 'Behandlung' && appointmentGroup.patient_selected && !appointmentGroup.rezept_selected">
              <v-row align="center">
                <v-col class="grow"> Rezept wurde noch nicht erfasst!</v-col>
                <v-col class="shrink">
                  <v-btn color="secondary" x-small @click="rezeptErfassen()">Erfassen</v-btn>
                </v-col>
              </v-row>
            </v-alert>

            <div v-if="appointmentGroup.gruppen_typ == 'Intern'">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="intern.titel" label="Titel" prepend-icon="mdi-format-title"
                    :error-messages="titelErrors" @input="v$.intern.titel.$touch()"
                    @blur="v$.intern.titel.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea label="Bemerkung" v-model="intern.bemerkung" rows="1"
                    prepend-icon="mdi-comment"></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-checkbox label="Ganztags?" v-model="intern.ganztag" @change="clearTimeInput"></v-checkbox>
                </v-col>

                <v-col cols="6">
                  <v-menu v-model="intern.menu.startDate" :close-on-content-click="false" transition="scale-transition"
                    offset-x min-width="auto">
                    <template v-slot:activator="{ on }">
                      <v-text-field ref="startDate" label="Startdatum" prepend-icon="mdi-calendar"
                        v-model="intern.selections.startDate" v-on="on" class="required" :error-messages="startDateErrors"
                        @input="handleInputDateText('intern', 'startDate')"
                        @focus="handleFocusDateText('intern', 'startDate')" @blur="v$.intern.startDate.$touch()"
                        placeholder="TT.MM.JJJJ"></v-text-field>
                    </template>
                    <v-date-picker v-model="intern.startDate" @input="handleInputDatePicker('intern', 'startDate')"
                      first-day-of-week="1" locale="de-de"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field ref="startTime" v-model="intern.startTime" prepend-icon="mdi-clock-outline"
                    label="Startzeit" type="time" :disabled="intern.ganztag" :error-messages="startTimeErrors"
                    @input="v$.intern.startTime.$touch()" @blur="v$.intern.startTime.$touch()"></v-text-field>
                </v-col>


                <v-col cols="6">
                  <v-menu v-model="intern.menu.endDate" :close-on-content-click="false" transition="scale-transition"
                    offset-x min-width="auto">
                    <template v-slot:activator="{ on }">
                      <v-text-field ref="endDate" label="Enddatum" prepend-icon="mdi-calendar"
                        v-model="intern.selections.endDate" v-on="on" class="required" :error-messages="endDateErrors"
                        @input="handleInputDateText('intern', 'endDate')"
                        @focus="handleFocusDateText('intern', 'endDate')" @blur="v$.intern.endDate.$touch()"
                        placeholder="TT.MM.JJJJ">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="intern.endDate" @input="handleInputDatePicker('intern', 'endDate')"
                      first-day-of-week="1" locale="de-de"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field ref="endTime" v-model="intern.endTime" prepend-icon="mdi-clock-outline" label="Endzeit"
                    type="time" :disabled="intern.ganztag" :error-messages="endTimeErrors"
                    @input="v$.intern.endTime.$touch()" @blur="v$.intern.endTime.$touch()"></v-text-field>
                </v-col>

              </v-row>


              <v-row>

                <v-col cols="12">
                  <v-autocomplete ref="mitarbeiter" label="Mitarbeiter" v-model="intern.mitarbeiter" class="required"
                    :items="mitarbeiterList" placeholder="Mitarbeiter auswählen" prepend-icon="mdi-doctor"
                    :item-text="mitarbeiter_text" item-value="id" return-object hide-no-data clearable :error-messages="mitarbeiterErrors"
                    @change="setMitarbeiterColor">
                  </v-autocomplete>
                </v-col>


                <v-col cols="8">
                  <v-dialog v-model="intern.colorDialog" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field label="Farbmarkierung" v-model="intern.color.hexa" prepend-icon="mdi-palette"
                        v-bind="attrs" v-on="on" readonly></v-text-field>
                    </template>

                    <v-card>
                      <v-color-picker v-model="intern.color" dot-size="10" show-swatches swatches-max-height="100"
                        hide-inputs>
                      </v-color-picker>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="primary-nav-btn" text color="primary" @click="intern.colorDialog = false">
                          Ok
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="4">
                  <v-text-field solo readonly disabled :background-color="intern.color.hexa"></v-text-field>
                </v-col>
              </v-row>



            </div>

            <div v-if="appointmentGroup.gruppen_typ == 'Behandlung'">
            </div>
          </v-container>
        </v-card-text>




        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeWindow"> Zurück </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text @click="openDeleteDialog" :disabled="appointmentGroup.gruppen_typ == 'Behandlung'"> Löschen </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text @click="editTermine" :disabled="appointmentGroup.gruppen_typ == 'Behandlung'"> Speichern </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="deleteDialog" persistent max-width="450px">
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Termin löschen</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDeleteDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span>Wollen Sie alle aufgeführten Termine wirklich löschen?</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDeleteDialog"> Abbrechen </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text @click="deleteTermine"> Löschen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" persistent max-width="650px">
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Termin Validierung</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeErrorDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span>Die folgenden Termine konnten nicht validiert werden:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(value, key) in errorData" :key="key">
                    <v-expansion-panel-header>
                      <b>{{ value.name }}</b>{{ value.start }} - {{ value.end }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ul>
                        <li v-for="(error, index) in value.errors" :key="index">{{ error }}</li>
                      </ul>

                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-nav-btn" color="primary" text @click="closeErrorDialog"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-row>
</template>

<script>
import moment from "moment";
import { mapMutations, mapActions } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { requiredUnless } from '@vuelidate/validators'

const initialData = () => ({
  loading: false,
  deleteDialog: false,
  errorDialog: false,
  errorData: null,

  termintyp: "",
  groupId: null,

  intern: {
    termin: null,

    titel: "",
    bemerkung: "",
    ganztag: false,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    mitarbeiter: null,
    color: {
      hexa: "#B43962FF",
    },

    menu: {
      startDate: false,
      endDate: false,
    },
    selections: {
      startDate: null,
      endDate: null,
    },

    colorDialog: false
  }
})

function startDateIsBeforeEndDate(date) {
  if (this.appointmentGroup.gruppen_typ == "Intern" && date && this.intern.endDate) {
    return moment(date).isSameOrBefore(moment(this.intern.endDate));
  }
  return true;
}

function startDateTimeIsBeforeEndDateTime(time) {
  // check if start date and end date are present
  if (this.appointmentGroup.gruppen_typ == "Intern" && this.intern.startDate && this.intern.endDate && time && this.intern.endTime) {
    let start = moment(this.intern.startDate + " " + time, "YYYY-MM-DD HH:mm");
    let end = moment(this.intern.endDate + " " + this.intern.endTime, "YYYY-MM-DD HH:mm");
    return start.isBefore(end);
  }
  return true;
}



export default {
  name: "TerminEdit",
  setup() {
    return { v$: useVuelidate() }
  },
  validations: {
    intern: {
      titel: {
        required: requiredUnless(function () {
          return this.termintyp == "Behandlung";
        }),
      },
      startTime: {
        required: requiredUnless(function () {
          return this.intern.ganztag || this.termintyp == "Behandlung";
        }),
        startDateTimeIsBeforeEndDateTime,
      },
      endTime: {
        required: requiredUnless(function () {
          return this.intern.ganztag || this.termintyp == "Behandlung";
        }),
        // TODO: check if end_time is after start_time (if both are set) but include dates
      },
      startDate: {
        required: requiredUnless(function () {
          return this.termintyp == "Behandlung";
        }),
        startDateIsBeforeEndDate
      },
      endDate: {
        required: requiredUnless(function () {
          return this.termintyp == "Behandlung";
        }),
      },
      mitarbeiter	: {
        required: requiredUnless(function () {
          return this.termintyp == "Behandlung";
        }),
      },

    },

  },

  data: function () {
    return initialData();
  },

  computed: {

    active() {
      return this.$store.getters["overlays/editIsActive"];
    },

    termine() {
      return this.$store.state.overlays.edit.termine;
    },

    appointmentGroup() {
      return this.$store.state.overlays.edit.termingruppe;
    },

    mitarbeiterList() {
      return this.$store.getters["mitarbeiter/getMitarbeiter"];
    },

    // Errors
    titelErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.titel;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Titel ist leer!");
      }

      return errors;
    },

    startDateErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.startDate;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Startdatum ist leer!");
        entry.startDateIsBeforeEndDate.$invalid && errors.push("Startdatum ist nach dem Enddatum!");
      }

      return errors;
    },

    startTimeErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.startTime;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Startzeit ist leer!");
        entry.startDateTimeIsBeforeEndDateTime.$invalid && errors.push("Startzeit ist nach der Endzeit!");
      }

      return errors;
    },

    endDateErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.endDate;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Enddatum ist leer!");
      }

      return errors;
    },

    endTimeErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.endTime;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Endzeit ist leer!");
      }

      return errors;
    },

    mitarbeiterErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.mitarbeiter;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Mitarbeiter ist leer!");
      }

      return errors;
    },

  },
  methods: {
    ...mapMutations("overlays", [
      "clearTimeInput", //TODO: replace with a component method (instead of a vuex mutation)
      "openAddRezept",
    ]),

    ...mapActions("termine", ["deleteTerminGruppe"]),
    ...mapActions("overlays", ["closeEditTerminandReset"]),

    patientErfassen() {
      this.openAddPatient(this.appointmentGroup);
    },

    rezeptErfassen() {
      this.openAddRezept(this.appointmentGroup);
    },

    closeWindow() {
      // Reset errors
      this.v$.$reset();

      // Reset data
      Object.assign(this.$data, initialData());

      // Close window
      this.closeEditTerminandReset();
    },

    async editTermine() {
      // Validate
      this.v$.$touch();

      if (this.v$.$errors.length == 0) {
        let data = null;

        if (this.appointmentGroup.gruppen_typ == 'Intern') {
          // Create data object
          data = {
            id: this.groupId,
            bemerkung: this.intern.bemerkung,
            kunde: this.intern.termin.kunde,
            patient: null,
            incompletePatientData: null,
            termintyp: "Intern",
            termine: [
              {
                id: this.intern.termin.id,
                name: this.intern.titel,
                start: null,
                end: null,
                ganztag: this.intern.ganztag,
                mitarbeiter: this.intern.mitarbeiter ? this.intern.mitarbeiter.id : null,
                color: this.intern.color.hex,
                behandlungen: null,
              }
            ]
          }

          // Create moment objects from date string
          let s = moment(this.intern.startDate).utc(true);
          let e = moment(this.intern.endDate).utc(true);

          if (!this.intern.ganztag) {
            s.hour(this.intern.startTime.substring(0, 2)).minute(this.intern.startTime.substring(3));
            e.hour(this.intern.endTime.substring(0, 2)).minute(this.intern.endTime.substring(3));
          }
          else {
            // Set start and end to beginning and end of the day
            s.hour(0).minute(0).second(0);
            e.hour(23).minute(59).second(59);
          }

          // Convert to ISO string
          data.termine[0].start = s.toISOString();
          data.termine[0].end = e.toISOString();
        }

        // Send data to server
        let errors = await this.$store.dispatch("termine/updateAppointments", data);

        // check if object is not empty
        if (Object.keys(errors).length > 0){

          this.errorData = {}

          for (const termin of data.termine) {
            if (errors[termin.id]) {

              this.errorData[termin.id] = {
                name: termin.name,
                start: moment(termin.start.slice(0, -1)).format("DD.MM.YYYY HH:mm"),
                end: moment(termin.end.slice(0, -1)).format("DD.MM.YYYY HH:mm"),
                errors: errors[termin.id] 
              }

              // Set error message
              termin.error = errors[termin.id];
            }
          }



          // Show error dialog
          this.errorDialog = true;
        }

        else {
          // Close window
          this.closeWindow();
        }
      }
    },

    async deleteTermine() {
      await this.deleteTerminGruppe(this.groupId);
      this.closeDeleteDialog();
      this.closeWindow();
    },

    openDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    closeErrorDialog() {
      this.errorDialog = false;
    },

    clearTimeInput() {
      this.intern.startTime = null;
      this.intern.endTime = null;
    },

    handleInputDateText(type, key) {
      let isValid = false

      // Format date text
      if (this[type].selections[key]) {
        let selection_date = this[type].selections[key];
        let m = moment(selection_date, "DD.MM.YYYY", true);
        if (m.isValid()) {
          // Set formatted date

          this[type][key] = m.format("YYYY-MM-DD");
          // Set valid flag
          isValid = true;

          // Close menu
          setTimeout(() => {
            this[type].menu[key] = false;
          }, 200);
        }
      }

      // Reset date text if invalid
      if (!isValid) {
        this[type][key] = null;
      }

      // Touch validation
      this.v$[type][key].$touch();
    },

    handleFocusDateText(type, key) {
      let menu_names = ["startDate", "endDate"].filter((item) => item != key);

      setTimeout(() => {
        // Close other menus
        menu_names.forEach((menu_name) => {
          this[type].menu[menu_name] = false;
        });

        // Open focused menu
        this[type].menu[key] = true;
      }, 200);
    },

    handleInputDatePicker(type, key) {
      let focusLkp = {
        startDate: ["startTime", "endDate"],
        endDate: ["endTime", "mitarbeiter"],
      }

      // Set date text
      this[type].selections[key] = moment(this[type][key]).format("DD.MM.YYYY");

      // Close menu
      this[type].menu[key] = false;

      // Focus next input
      const resolutionOrder = focusLkp[key];
      if (resolutionOrder) {
        // Find first existing ref and focus it and stop
        for (var entry in resolutionOrder) {
          let ref = resolutionOrder[entry];
          if (this.$refs[ref]) {
            this.$refs[ref].focus();
            break;
          }
        }
      }


    },

    mitarbeiter_text: (item) => item.name + ", " + item.vorname,

    setMitarbeiterColor() {
      if (this.intern.mitarbeiter) {
        this.intern.color.hex = this.intern.mitarbeiter.color;
        this.intern.color.hexa = this.intern.mitarbeiter.color + "FF";
      }
    },

    initTerminData() {
      this.groupId = this.appointmentGroup.id
      if (this.appointmentGroup.gruppen_typ == "Intern") {
        this.intern.termin = this.termine[0];

        // Set values
        this.intern.titel = this.intern.termin.name;
        this.intern.bemerkung = this.intern.termin.bemerkung;
        this.intern.ganztag = this.intern.termin.ganztag;
        this.intern.color.hexa = this.intern.termin.color;

        // Find mitarbeiter based on the category name
        this.intern.mitarbeiter = this.mitarbeiterList.find((item) => this.mitarbeiter_text(item) == this.intern.termin.category);

        // Set date and time values
        if (this.intern.ganztag) {
          // start/end will only contain date without the time part
          this.intern.startDate = this.intern.termin.start;
          this.intern.selections.startDate = moment(this.intern.termin.start).format("DD.MM.YYYY");

          this.intern.endDate = this.intern.termin.end;
          this.intern.selections.endDate = moment(this.intern.termin.end).format("DD.MM.YYYY");
        }
        else {
          // start/end will contain date and time e.g. 2021-03-01T08:00:00
          let sm = moment(this.intern.termin.start, "YYYY-MM-DDTHH:mm:ss", true);
          this.intern.startDate = sm.format("YYYY-MM-DD");
          this.intern.selections.startDate = sm.format("DD.MM.YYYY");
          this.intern.startTime = sm.format("HH:mm");

          let em = moment(this.intern.termin.end, "YYYY-MM-DDTHH:mm:ss", true);
          this.intern.endDate = em.format("YYYY-MM-DD");
          this.intern.selections.endDate = em.format("DD.MM.YYYY");
          this.intern.endTime = em.format("HH:mm");
        }
      }
    }

  },

  watch: {
    active: function (val) {
      if (val) {
        this.initTerminData();
      }
    }

  },

  mounted() { },
};
</script>
<style></style>
