<template>
  <v-row justify="center">
    <v-dialog
      v-model="active"
      persistent
      max-width="550px"
      transition="dialog-bottom-transition"
    >
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <v-spacer />
          <v-icon left> mdi-message-alert </v-icon>
          <span>Änderung im Zuzahlungswert</span>
          <v-spacer />
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container>
            <v-row style="text-align: center">
              <v-col cols="12">
                <div class="my-4" v-if="warningType=='groupRemovedWithExcessPayment'">
                  <p>
                    Durch den Abbruch aller Termine wird der Zuzahlungswert im
                    <strong>Kassenbuch</strong> / in den
                    <strong>Offenen Posten</strong> storniert und das
                    dazugehörige Rezept gelöscht.
                  </p>

                  <p style="font-weight: 600;">
                    Bitte stellen Sie sicher, dass der entsprechende bereits
                    bezahlte Wert an den Patienten zurückgezahlt wird.
                  </p>
                </div>

                <div class="my-4" v-if="warningType=='excessPayment'">
                  <div class="pb-3">
                    Durch die Änderung der Termine hat sich der Zuzahlungswert
                    geändert:
                  </div>

                  <v-simple-table dense class="pb-3">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Alter Zuzahlungswert
                          </th>
                          <th class="text-center">
                            Neuer Zuzahlungswert
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ oldValue | formatDecimal }} €</td>
                          <td>{{ newValue | formatDecimal }} €</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div>
                    <p>
                      Da für dieses Rezept bereits eine Quittung existiert,
                      wurden zusätzliche Einträge im
                      <strong>Kassenbuch</strong> / in den
                      <strong>Offenen Posten</strong> vermerkt.
                    </p>
                    <p style="font-weight: 600;">
                      Bitte stellen Sie sicher, dass ausstehende Einnahmen und
                      Ausgaben eingehalten werden!
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-nav-btn" color="primary" @click="close"
            >Verstanden</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const initialData = () => ({
});

export default {
  name: "ExcessPaymentNotification",

  data() {
    return initialData();
  },


  filters: {
    formatDecimal(value) {
      return value.toFixed(2);
    },
  },



  computed: {
    
    active() {
      return this.$store.getters["overlays/excessPaymentNotificationActive"];
    },

    warningType() {
      return this.$store.getters["overlays/excessPaymentNotificationWarningType"];
    },

    oldValue() {
      return this.$store.getters["overlays/excessPaymentNotificationOldValue"];
    },

    newValue() {
      return this.$store.getters["overlays/excessPaymentNotificationNewValue"];
    },

  },

  methods: {
    close() {
      this.$store.dispatch("overlays/closeExcessPaymentNotificationAndReset");
    },
  },

  watch: {},

  mounted() {},
};
</script>

<style scoped></style>
