import axios from "axios";
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

const state = {
  holidays: {},
};

const getters = {
  getHolidays: (state) => {
    return state.holidays;
  },

  getHolidayByDate: (state) => (date) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    if (state.holidays === null ||state.holidays[date_str] === undefined) {
      return null;
    }

    return state.holidays[date_str];
  },

  isHoliday: (state) => (date) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    return date_str in state.holidays
  },
};

const actions = {
  async GetHolidaysData({ commit, rootGetters }) {

    // Get current and next year
    // TODO: Realistically, this should be refetched once the user gets to the next year but for now, we'll just fetch one year ahead
    let now = moment();
    let currentYear = now.year();
    let nextYear = now.add(1, "year").year();

    const years = [currentYear, nextYear];

    let config = {
      headers: rootGetters['auth/authHeaders'],
      params: {
        year: years.join(",") // will be converted to "?year=2023,2024"
      },
    };

    return axios
      .get("appointments/holidays/", config)
      .then((res) => {
        commit("setHolidayData", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  setHolidayData(state, data) {
    // Data is an array of objects so to allow us to easily access the data by date, we'll convert it to an object
    // {
    //   "2023-01-01": {name: "New Year's Day", state: "BW"},
    // }
    let holidays = {};
    for (const entry of data.results) {
      holidays[entry.date] = entry;
    }

    state.holidays = holidays;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
