<template>
    <v-card flat>
        <v-card-text>

            <v-row align="center" class="pb-5">
                <v-col class="d-flex" cols="12" sm="6">
                    <v-select label="Mitarbeiter" v-model="mitarbeiterSelected" :items="mitarbeiter"
                        placeholder="Mitarbeiter auswählen" prepend-icon="mdi-doctor"
                        :item-text="(item) => item.name + ', ' + item.vorname" :item-value="(item) => item.id"
                        hint="Hinweis: Nicht gespeicherte Änderungen werden bei Neuauswahl zurückgesetzt"
                        persistent-hint
                        >
                    </v-select>
                </v-col>
            </v-row>

            <div v-for="(entry, key) in workingHours[mitarbeiterSelected]" class="mb-3" :key="entry.label">
                <v-row>
                    <v-col style="margin-top: 9px; max-width: 12%;">
                        <span style="font-weight: 500; font-size: 16px;">{{
                            entry.label
                        }}</span>
                    </v-col>
                    <v-col cols="2">
                        <v-switch v-model="entry.available" :label="(entry.available && 'Anwesend') || 'Abwesend'"
                            color="primary" class="mt-2 pt-0" dense
                            hide-details @change="toggleWorkingHourState(key)">
                        </v-switch>
                    </v-col>
                    <v-col cols="6">
                        <div v-for="(value, id) in entry.times" :class="{ 'pb-3': (id != entry.times.length - 1) }"
                            :key="id">

                            <v-menu :ref="'menu-' + key + '-' + id + '-start'"
                                v-model="menus['menu-' + key + '-' + id + '-start']" :close-on-content-click="false"
                                :return-value.sync="value[0]" transition="scale-transition" offset-y max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="value[0]" dense class="mx-4 mt-0" outlined
                                        @change="setAndCheckTempArbeitszeiten(workingHours[mitarbeiterSelected][key])" hide-details readonly
                                        v-bind="attrs" v-on="on" label="Anwesend von"
                                        style="display: inline-flex; width: 130px;">
                                    </v-text-field>
                                </template>
                                <v-time-picker v-if="menus['menu-' + key + '-' + id + '-start']" v-model="value[0]"
                                    full-width
                                    @click:minute="saveRefTime('menu-' + key + '-' + id + '-start', value[0], key)"
                                    :allowed-minutes="allowedMinuteStep" format="24hr" :min="getMinTime(key, id, 0)"
                                    :max="getMaxTime(key, id, 0)">
                                </v-time-picker>
                            </v-menu>

                            -
                            <v-menu :ref="'menu-' + key + '-' + id + '-end'"
                                v-model="menus['menu-' + key + '-' + id + '-end']" :close-on-content-click="false"
                                :return-value.sync="value[1]" transition="scale-transition" offset-y max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="value[1]" dense class="mx-4 mt-0" outlined
                                        @change="setAndCheckTempArbeitszeiten(workingHours[mitarbeiterSelected][key])" hide-details readonly
                                        v-bind="attrs" v-on="on" label="Anwesend bis"
                                        style="display: inline-flex; width: 130px;">
                                    </v-text-field>
                                </template>
                                <v-time-picker v-if="menus['menu-' + key + '-' + id + '-end']" v-model="value[1]"
                                    full-width @click:minute="saveRefTime('menu-' + key + '-' + id + '-end', value[1], key)"
                                    :allowed-minutes="allowedMinuteStep" format="24hr" :min="getMinTime(key, id, 1)"
                                    :max="getMaxTime(key, id, 1)">
                                </v-time-picker>
                            </v-menu>


                            <v-btn icon style="display: inline-flex;" @click="removeTime(key, id)">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>

                            <v-btn v-if="id == entry.times.length - 1 && canAddTime(key)" style="display: inline-flex;"
                                text @click="addTime(key)">
                                Zeit hinzufügen
                            </v-btn>

                        </div>

                    </v-col>
                    <v-spacer></v-spacer>


                </v-row>
            </div>



        </v-card-text>
    </v-card>
</template>

<script>
import moment from "moment"
import { mapActions, mapGetters, mapMutations} from "vuex";


const initialData = () => ({
    weekdays: [
        "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"
    ],

    mitarbeiterSelected: null,
    workingHours: {},
    workingHoursCopy: {},
    menus: {},
    defaultOpenInterval: ["08:00", "20:00"]
});

export default {
    name: "MitarbeiterArbeitszeiten",

    data() {
        return initialData();
    },

    computed: {
        mitarbeiter() {
            return this.$store.getters["mitarbeiter/getMitarbeiter"];
        },
    },
    methods: {
        ...mapGetters("mitarbeiterarbeitszeiten", ["getArbeitszeiten"]),
        ...mapActions("mitarbeiterarbeitszeiten", ["setAndCheckTempArbeitszeiten"]),
        ...mapMutations("mitarbeiterarbeitszeiten", ["resetTempArbeitszeiten", "setCurrentEmployee"]),

        allowedMinuteStep: m => m % 5 === 0,

        toggleWorkingHourState(weekday) {
            this.workingHours[this.mitarbeiterSelected][weekday].times = []

            if (this.workingHours[this.mitarbeiterSelected][weekday].available) {
                this.workingHours[this.mitarbeiterSelected][weekday].times.push([...this.defaultOpenInterval])
            }
            this.setAndCheckTempArbeitszeiten(this.workingHours[this.mitarbeiterSelected][weekday])
        },

        saveRefTime(ref_key, value, weekday) {
            this.$refs[ref_key][0].save(value)
            this.setAndCheckTempArbeitszeiten(this.workingHours[this.mitarbeiterSelected][weekday])
        },

        getMinTime(weekday, entry_id, time_id) {
            var minTime = null

            if (entry_id == 0) {
                if (time_id == 0) {
                    minTime = "00:00"
                }
                else {
                    minTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id][0]).add(5, "minutes").format("HH:mm")
                }
            }
            else {
                if (time_id == 0) {
                    minTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id - 1][1]).add(5, "minutes").format("HH:mm")
                }
                else {
                    minTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id][0]).add(5, "minutes").format("HH:mm")
                }

            }

            return minTime
        },

        getMaxTime(weekday, entry_id, time_id) {
            var maxTime = null

            if (entry_id == this.workingHours[this.mitarbeiterSelected][weekday].times.length - 1) {
                if (time_id == 1) {
                    maxTime = "23:59"
                }
                else {
                    maxTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id][1]).subtract(5, "minutes").format("HH:mm")
                }
            }
            else {
                if (time_id == 0) {
                    maxTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id][1]).subtract(5, "minutes").format("HH:mm")
                }
                else {
                    maxTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id + 1][0]).subtract(5, "minutes").format("HH:mm")
                }
            }

            return maxTime
        },

        canAddTime(weekday) {
            var lastTimePairTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times.slice(-1)[0][1])
            var nextValidEndTime = lastTimePairTime.add(10, "minutes")
            return lastTimePairTime.isSame(nextValidEndTime, "day")
        },

        addTime(weekday) {
            var lastTimePairTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times.slice(-1)[0][1])
            var nextValidStartTime = lastTimePairTime.add(5, "minutes").format("HH:mm")
            var nextValidMaxEndTime = "23:59"

            this.workingHours[this.mitarbeiterSelected][weekday].times.push([nextValidStartTime, nextValidMaxEndTime])
            this.setAndCheckTempArbeitszeiten(this.workingHours[this.mitarbeiterSelected][weekday])
        },

        removeTime(weekday, time_id) {
            this.workingHours[this.mitarbeiterSelected][weekday].times.splice(time_id, 1)

            if (this.workingHours[this.mitarbeiterSelected][weekday].times.length == 0) {
                this.workingHours[this.mitarbeiterSelected][weekday].available = false
            }
            this.setAndCheckTempArbeitszeiten(this.workingHours[this.mitarbeiterSelected][weekday])
        },

        setWorkingHours() {
            this.workingHours = JSON.parse(JSON.stringify(this.getArbeitszeiten()))
            this.workingHoursCopy = JSON.parse(JSON.stringify(this.getArbeitszeiten()))
        },

    },
    watch: {
        mitarbeiterSelected() {
            this.workingHours = JSON.parse(JSON.stringify(this.workingHoursCopy))
            this.setCurrentEmployee(this.mitarbeiterSelected)
            this.resetTempArbeitszeiten()
            
        }
    },

    mounted() {
        this.setWorkingHours()
        this.mitarbeiterSelected = this.mitarbeiter[0].id
        this.setCurrentEmployee(this.mitarbeiterSelected)
    },


}

</script>

<style>

.v-messages__message {
    line-height: 14px;
}

</style>