import axios from "axios";

const state = {
  mitarbeiter: [],
};

const getters = {
  getMitarbeiter: (state) => {
    return state.mitarbeiter;
  },

  getMitarbeiterbyId: (state) => (id) => {
    for (const mitarbeiter of state.mitarbeiter) {
      if (mitarbeiter.id == id) {
        return mitarbeiter;
      }
    }
  },

  getMitarbeiterNamebyId: (state) => (id) => {
    for (const mitarbeiter of state.mitarbeiter) {
      if (mitarbeiter.id == id) {
        return mitarbeiter.name + ", " + mitarbeiter.vorname;
      }
    }
  },

  getAvailableMitarbeiterByTermin: (state) => (termin) => {
    let mitarbeiter = [];

    if (termin.available_mitarbeiter) {
      for (const id of termin.available_mitarbeiter) {
        for (const m of state.mitarbeiter) {
          if (m.id == id) {
            mitarbeiter.push(m);
          }
        }
      }
    }
    return mitarbeiter;
  },

  getMitarbeiterIds: (state) => {
    let ids = [];
    state.mitarbeiter.forEach((element) => {
      ids.push(element.id);
    });
    return ids;
  },

  getMitarbeiterCount: (state) => {
    return state.mitarbeiter.length;
  },

  getMitarbeiterByCategory: (state) => (category) => {
    let result = state.mitarbeiter.filter((obj) => {
      let mcat = obj.name + ", " + obj.vorname;
      return mcat === category;
    });

    if (result && result.length > 0) {
      return result[0];
    }
  },
};

const actions = {
  async GetMitarbeiterData({ commit, rootGetters, dispatch, state }) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    return axios
      .get("mitarbeiter/", config)
      .then((res) => {
        commit("setMitarbeiterData", res.data);
        dispatch("hauptmenu/fixCalendarCategories", state.mitarbeiter, { root: true });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  setMitarbeiterData(state, data) {
    state.mitarbeiter = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
