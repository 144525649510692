import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";

const state = {
  user: null,
  refresh: null,
  access_token: null,
  refresh_token: null,
  timer: {
    start: moment(),
    value: 3600,
    interval: null,
  }
};

const getters = {
  isAuthenticated: (state) => !!state.access_token,
  currentUser: (state) => state.user,
  isTokenValid: (state) => {
    var current_time = Date.now() / 1000;
    return current_time < state.user.exp;
  },

  isRefreshValid: (state) => {
    var current_time = Date.now() / 1000;
    return current_time < state.refresh.exp;
  },

  authHeaders: (state) => {
    return { Authorization: "Bearer " + state.access_token };
  },


  getTimeLeftStr: (state) => {
    let minutes = Math.floor(state.timer.value / 60);
    let seconds = state.timer.value % 60;
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  },

  getTimerValue: (state) => {
    return state.timer.value;
  },

  getTimerStart: (state) => {
    return state.timer.start;
  },

};

const actions = {
  async LogIn({ commit }, user) {
    try {
      const response = await axios.post("login/", user);

      const mtp_access = jwt_decode(response.data.access).mtp_access;
      if (mtp_access) {
        commit("resetTimer");
        await commit("setData", response.data);
      } else {
        throw { id: "PERMISSION", msg: "Keine Berechtigung für den Zugriff!" };
      }
    } catch (err) {
      // pass on
      if (err.msg) {
        throw err;
      } else {
        if (err.response) {
          if (err.response.data.id == "AUTH") {
            throw { id: "AUTH", msg: "Benutzername / Passwort ist nicht korrekt!" };
          }
        } else {
          if (err.message == "Network Error") {
            throw { id: "SERVER", msg: "Netzwerk- oder Server-Probleme!" };
          }
        }
      }
    }
  },

  async LogOut({ commit }) {
    commit("resetTimer");
    commit("overlays/resetState", null, { root: true });
    commit("logout");
  },

  async RefreshToken({ commit, state, getters, dispatch }) {
    // ensure refresh token is still valid for usage
    if (getters.isRefreshValid) {
      const response = await axios.post("token-refresh/", { refresh: state.refresh_token });
      await commit("setNewToken", response.data);
      commit("resetTimer");
    } else {
      await dispatch("LogOut");
    }
  },
};

const mutations = {
  setData(state, data) {
    state.access_token = data.access;
    state.refresh_token = data.refresh;
    state.user = jwt_decode(data.access);
    state.refresh = jwt_decode(data.refresh);
  },

  logout(state) {
    state.user = null;
    state.access_token = null;
    state.refresh_token = null;
  },

  setNewToken(state, data) {
    state.access_token = data.access;
  },

  updateTimerInterval(state, value) {
    state.timer.interval = value;
  },

  updateTimerValue(state, value){
    state.timer.value = value;
  },

  resetTimer(state){
    clearInterval(state.timer.interval);
    state.timer.interval = null;
    state.timer.value = 3600;
    state.timer.timeoutShown = false;
    state.timer.start = moment();
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
