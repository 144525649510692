import axios from 'axios';

const state = {
    icd_codes: []
};

const getters = {
    getICDCodes: state => {
        return state.icd_codes
    }
};

const actions = {
    async Search({commit, rootGetters}, term) {
        if (term.length >= 3){
            let config = {
                headers: rootGetters['auth/authHeaders'],
                params: {
                    search: term,
                }
            }
            const response = await axios.get('icd10/', config)
            await commit('setICDCodes', response.data)
        }

      },
      
};
 
const mutations = {

    setICDCodes(state, data){
        state.icd_codes = data
    },

    clearICDCodes(state){
        state.icd_codes = []
    }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};