import axios from "axios";

const state = {
}

const getters = {
}

const actions = {

    async queryPartialPrescriptions({rootGetters}, params={}) {
        let config = {
            headers: rootGetters['auth/authHeaders'],
            params: params
        }

        try {
            const response = await axios.get("prescriptions/partial-appointments/", config);
            return response.data
        } catch (error) {
            throw Error(error.response.data.message)
        }
    },

    async fetchQuittungPDF({rootGetters}, data) {
        let config = {
            headers: rootGetters['auth/authHeaders'],
            responseType: "arraybuffer",
            params: {}
        }

        if (data.paymentMethod) {
            config.params.zahlart = data.paymentMethod
        }

        return axios.get(`quittung/${data.headerId}`, config).then(response => {
            let blob = new Blob([response.data], {type: 'application/pdf'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            // content-diposition contains a string like: attachment; filename="quittung-36640-03102024.pdf"
            // We need to extract the filename from this string and remove the quotes
            const filename = response.headers['content-disposition'].split('filename=')[1].slice(1,-1);
            link.download = filename;
            link.click();

            return response;
        }).catch(error => {
            return error.response;
        });
    }



}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
