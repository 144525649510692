import axios from "axios";

const state = {
  zeiteinheiten: [],
  vollprivatZeiteinheiten: [],
};

const getters = {
  getZeiteinheiten: (state) => {
    return state.zeiteinheiten;
  },

  getDefaultZeiteinheiten: (state) => (bezeichnungenToRemove) => {
    return state.zeiteinheiten.filter((e) => e.typ !== "custom" && !bezeichnungenToRemove.includes(e.abkuerzung));
  },

  getCustomZeiteinheiten: (state) => {
    return state.zeiteinheiten.filter((e) => e.typ == "custom");
  },

  getVollprivatZeiteinheiten: (state) => {
    return state.vollprivatZeiteinheiten;
  },
};

const actions = {
  async GetTarifZeiteinheitenData({ commit, rootGetters }, ktType = null) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    if (ktType) {
      config.params = {
        ktType: ktType,
      };
    }

    return axios
      .get("kundenzeiteinheiten/", config)
      .then((res) => {
        commit("setTarifZeiteinheitenData", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetVollprivatZeiteinheitenData({ commit, rootGetters }) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    return axios
      .get("vollprivattarif/", config)
      .then((res) => {
        commit("setVollprivatZeiteinheitenData", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async BulkOperationsKundenZeiteinheit({ commit, rootGetters }, data) {
    /* 
    # an empty request will delete all custom Zeiteinheiten
    # provided Zeiteinheiten will be created or modified
    # missing Zeiteinheiten will be deleted if present
    [
      {Zeiteinheit},
    ]
    }
    */
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    try {
      const response = await axios.post("kundenzeiteinheiten/bulk/", data, config);
      commit("setTarifZeiteinheitenData", response.data);
    } catch (error) {
      throw "Some error " + error;
    }
  },
};

const mutations = {
  setTarifZeiteinheitenData(state, data) {
    state.zeiteinheiten = data;
  },

  setVollprivatZeiteinheitenData(state, data) {
    state.vollprivatZeiteinheiten = data;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
