<template>
    <v-row justify="center">
        <v-dialog v-model="active" persistent max-width="800px">
            <v-card light>
                <v-card-title>
                    <span class="headline">Neuer Arzt</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeWindow">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="8">
                                <v-text-field v-model="doctor.name" label="Name" prepend-icon="mdi-doctor" class="required"
                                    :error-messages="nameErrors" @input="v$.doctor.name.$touch()"
                                    @blur="v$.doctor.name.$touch()"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="4">
                                <v-text-field v-model="doctor.arzt_num" label="LANR" prepend-icon="mdi-alpha-a-circle"
                                    v-mask="[...masks.arzt_num]" class="required" :error-messages="arztNumErrors"
                                    @input="v$.doctor.arzt_num.$touch()" @blur="v$.doctor.arzt_num.$touch()"></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4">
                                <v-text-field v-model="doctor.betriebs_num" label="BSNR" prepend-icon="mdi-alpha-b-circle"
                                    v-mask="[...masks.betriebs_num]" class="required" :error-messages="betriebsNumErrors"
                                    @input="v$.doctor.betriebs_num.$touch()"
                                    @blur="v$.doctor.betriebs_num.$touch()"></v-text-field>
                            </v-col>
                        </v-row>


                        <v-divider class="py-2 mt-4"></v-divider>

                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="4">
                                <v-text-field v-model="doctor.strasse" ref="strasse" label="Strasse"
                                    prepend-icon="mdi-home"></v-text-field>
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="4">
                                <v-text-field v-model="doctor.plz" label="PLZ" prepend-icon="mdi-counter"
                                    v-mask="[...masks.plz]" :error-messages="plzErrors" @input="v$.doctor.plz.$touch()"
                                    @blur="v$.doctor.plz.$touch()"></v-text-field>
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="4">
                                <v-text-field v-model="doctor.ort" label="Ort" prepend-icon="mdi-home-city">
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row align="center" class="mt-0">
                            <v-col class="d-flex" cols="12" sm="4">
                                <v-text-field v-model="doctor.telefon" label="Telefon" prepend-icon="mdi-phone" type="tel"
                                    v-mask="masks.phone_num"></v-text-field>
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="4">
                                <v-text-field v-model="doctor.fax" label="Fax" type="tel" prepend-icon="mdi-fax"
                                    v-mask="masks.phone_num"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeWindow" :disabled="areButtonsDisabled">Zurück</v-btn>
                    <v-btn class="primary-nav-btn" color="primary" text @click="runCreateDoctor" :disabled="areButtonsDisabled"
                        :loading="areButtonsDisabled">Speichern</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { minLength, maxLength, required } from '@vuelidate/validators';
import { mapActions, mapMutations } from "vuex";


const nameValidator = (value) => {
    // Checks using a regular expression if the name is valid
    const pattern = /^[A-Za-zßöÖäÄüÜéÉèÈ/. -&+']+$/;
    return pattern.test(value);
}

const initialData = () => ({
    doctor: {
        kunde: "",
        arzt_num: "",
        betriebs_num: "",
        name: "",
        strasse: "",
        plz: "",
        ort: "",
        telefon: "",
        fax: "",
    },

    masks: {
        arzt_num: ["#########"],
        betriebs_num: ["#########"],
        phone_num: {
            mask: 'PPPPPPPPPPPPPPPP',
            tokens: {
                P: {
                    pattern: /[0-9/-/+]/,
                }
            },

        },
        plz: ["#####"],
    },
    areButtonsDisabled: false,

    vuelidateExternalResults: {
        doctor: {
            arzt_num: [],
            betriebs_num: [],
            name: [],
            plz: [],
        }
    }
}
);

export default {
    name: "DoctorAdd",

    setup() {
        return { v$: useVuelidate() }
    },


    validations: {
        doctor: {
            arzt_num: {
                required,
                minLength: minLength(9),
                maxLength: maxLength(9),

            },
            betriebs_num: {
                required,
                minLength: minLength(9),
                maxLength: maxLength(9)
            },
            name: {
                required,
                nameValidator: nameValidator,
            },
            plz: {
                minLength: minLength(5),
                maxLength: maxLength(5),
            },
        }
    },

    data: function () {
        return initialData();
    },

    computed: {
        active() {
            return this.$store.getters["overlays/doctorAddActive"];
        },

        defaults() {
            return this.$store.getters["overlays/doctorAddDefaults"];
        },

        nameErrors() {
            const errors = [];
            if (this.v$) {
                const entry = this.v$.doctor.name;
                if (!entry.$dirty) return errors;

                entry.required.$invalid && errors.push("Name ist leer!")
                entry.nameValidator.$invalid && errors.push("Name darf nur Buchstaben/Satzzeichen enthalten!")
            }
            return errors;
        },

        arztNumErrors() {
            const errors = [];
            if (this.v$) {
                const entry = this.v$.doctor.arzt_num;
                if (!entry.$dirty) return errors;

                entry.required.$invalid && errors.push("Arztnummer ist leer!");
                (entry.minLength.$invalid || entry.maxLength.$invalid) && errors.push("Arztnummer muss 9 Zeichen lang sein!");

                if (this.vuelidateExternalResults.doctor.arzt_num.length > 0) {
                    errors.push(this.vuelidateExternalResults.doctor.arzt_num[0]);
                }

                this.v$.$clearExternalResults();
            }
            return errors;
        },

        betriebsNumErrors() {
            const errors = [];
            if (this.v$) {
                const entry = this.v$.doctor.betriebs_num;
                if (!entry.$dirty) return errors;

                entry.required.$invalid && errors.push("Betriebsnummer ist leer!");
                (entry.minLength.$invalid || entry.maxLength.$invalid) && errors.push("Betriebsnummer muss 9 Zeichen lang sein!");
            }
            return errors;
        },

        plzErrors() {
            const errors = [];
            if (this.v$) {
                const entry = this.v$.doctor.plz;
                if (!entry.$dirty) return errors;
                (entry.minLength.$invalid || entry.maxLength.$invalid) && errors.push("PLZ muss 5 Zeichen lang sein!");
            }
            return errors;
        },
    },

    methods: {
        ...mapMutations("overlays", ["closeAddDoctor", "addLastAddedDoctor"]),
        ...mapActions("aerzte", ["createDoctor"]),

        closeWindow() {
            // Reset errors
            this.v$.$reset();

            // Reset data
            Object.assign(this.$data, initialData());

            // Close window
            this.closeAddDoctor();
        },

        handleNonFieldErrors(errors) {
            let errorObject = {
                doctor: {
                    arzt_num: [],
                    betriebs_num: [],
                    name: [],
                    plz: [],
                }
            }
            for (let key of errors) {
                if (key == "ArztExists") {
                    errorObject.doctor.arzt_num.push("Arzt existiert bereits!");
                }
            }

            Object.assign(this.vuelidateExternalResults, errorObject);
        },

        async runCreateDoctor() {
            // Validate
            this.v$.$touch();

            // Check if there are errors
            if (this.v$.$error) {
                return;
            }

            // Disable buttons
            this.areButtonsDisabled = true;

            // Add Kunde
            this.doctor.kunde = this.$store.getters["auth/currentUser"].user_id;

            // Create doctor
            await this.createDoctor(this.doctor).then((response) => {
                if (response.status == 201) {

                    // Add doctor to list
                    this.addLastAddedDoctor(this.doctor.arzt_num)

                    // Close window
                    this.closeWindow();
                } else {
                    if (response.data && response.data.non_field_errors) {
                        this.handleNonFieldErrors(response.data.non_field_errors);
                    } else {
                        console.log("Unknown error");
                    }
                }
            });

            // Enable buttons
            this.areButtonsDisabled = false;


        },
    },

    watch: {
        active() {
            if (this.defaults && this.defaults.arzt_num) {
                this.doctor.arzt_num = this.defaults.arzt_num;
            }
        }
    },

    mounted() { },
};
</script>

<style></style>
