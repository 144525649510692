import axios from "axios";

const getDefaultState = () => {
  return {
    kostentraeger: {
      KK: [],
      PBeaKK: [],
      S: [],
      BG: [],
    },
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getKostentraegerWithKtTyp: (state) => (kt_typ) => {
    return state.kostentraeger[kt_typ];
  },

  getKostentraeger: (state) => {
    return state.kostentraeger;
  },
};

const actions = {
  async Search({ commit, rootGetters }, params) {
    let term = params.term;
    let kt_typ = params.kt_typ;

    if (term.length >= 3) {
      let config = {
        headers: rootGetters['auth/authHeaders'],
        params: {
          search: term,
          kt_typ: kt_typ,
        },
      };
      const response = await axios.get("kostentraeger/", config);
      const kt_data = { kt_typ: kt_typ, kt_list: response.data };
      await commit("setKostentraeger", kt_data);
    }
  },
};

const mutations = {
  setKostentraeger(state, data) {
    state.kostentraeger[data.kt_typ] = data.kt_list;
  },

  resetKostentraeger(state) {
    const data = getDefaultState();
    Object.keys(data).forEach((k) => (state[k] = data[k]));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
