<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="400px" transition="dialog-bottom-transition">
      <v-card light>
        <v-card-title v-if="expanded" style="background-color: #f5f5f5">
          <v-spacer />
          <v-icon left> mdi-drag-horizontal-variant </v-icon>
          <span>Terminverlängerung/-verkürzung</span>
          <v-spacer />
        </v-card-title>

        <v-card-title v-if="dragged || dragged_category || moved || moved_category" style="background-color: #f5f5f5">
          <v-spacer />
          <v-icon left> mdi-cursor-move </v-icon>

          <span>Terminverschiebung</span>
          <v-spacer />
        </v-card-title>

        <!-- No errors found case -->
        <div v-if="errors.length == 0">
          <v-card-text>
            <v-container>
              <v-row style="text-align: center">
                <v-col cols="12">
                  <div v-if="expanded">
                    Sind Sie sicher, dass Sie die Termindauer verlängern bzw.
                    verkürzen möchten?
                  </div>
                  <div v-if="dragged || moved">
                    Sind Sie sicher, dass Sie den Termin verschieben möchten?
                  </div>
                  <div v-if="dragged_category || moved_category">
                    Sind Sie sicher, dass Sie den Termin auf einen anderen
                    Mitarbeiter verschieben möchten?
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="cancel" :disabled="isLoading">Nein</v-btn>
            <v-btn class="primary-nav-btn" color="primary" style="width: 6rem" @click="accept" :loading="isLoading" :disabled="isLoading">Ja</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>

        <!-- Errors found case -->
        <div v-if="errors.length != 0">
          <v-card-text>
            <v-container>
              <v-row style="text-align: center">
                <v-col cols="12">
                  <div v-if="expanded" class="pb-3">
                    Die Verlängerung bzw. Verkürzung der Termindauer ist aus
                    folgenden Gründen nicht möglich:
                  </div>

                  <div v-if="dragged || moved" class="pb-3">
                    Die Verschiebung des Termins ist aus folgenden Gründen nicht
                    möglich:
                  </div>

                  <div v-if="dragged_category || moved_category" class="pb-3">
                    Die Verschiebung des Termins auf einen anderen Mitarbeiter
                    ist aus folgenden Gründen nicht möglich:
                  </div>

                  <ul v-for="(error, index) in errors" :key="index">
                    <b>{{ error }} </b>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary-nav-btn" color="primary" @click="cancel">Verstanden</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

const initialData = () => ({});

export default {
  name: "DragConfirmation",

  data() {
    return initialData();
  },

  computed: {
    active() {
      return this.$store.getters["overlays/dragConfirmationActive"];
    },

    isLoading() {
      return this.$store.getters["overlays/dragConfirmationLoading"];
    },

    dragged() {
      return this.$store.getters["overlays/dragConfirmationDrag"];
    },

    dragged_category() {
      return this.$store.getters["overlays/dragCategoryConfirmationDrag"];
    },

    moved() {
      return this.$store.getters["overlays/dragConfirmationMove"];
    },

    moved_category() {
      return this.$store.getters["overlays/dragCategoryConfirmationMove"];
    },

    expanded() {
      return this.$store.getters["overlays/dragConfirmationExtend"];
    },

    errors() {
      return this.$store.getters["overlays/dragConfirmationErrors"];
    },
  },

  methods: {
    ...mapActions("overlays", [
      "closeDragConfirmationAndResetTermin",
      "closeDragConfirmationAndUpdateTermin",
    ]),

    cancel() {
      this.closeDragConfirmationAndResetTermin();
    },

    accept() {
      this.closeDragConfirmationAndUpdateTermin();
    },
  },
  watch: {},

  mounted() { },
};
</script>

<style>
</style>