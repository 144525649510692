import axios from "axios";

const state = {
  patienten: [],
};

const getters = {
  getPatienten: (state) => {
    return state.patienten;
  },
};

const actions = {
  async Search({ commit, rootGetters }, data) {

    let term = data.term;
    let includeIncomplete = data.includeIncomplete;

    if (term.length >= 2) {
      let config = {
        headers: rootGetters['auth/authHeaders'],
        params: {
          search: term,
          includeIncomplete: includeIncomplete,
        },
      };
      const response = await axios.get("patienten/", config);
      await commit("setPatienten", response.data.results);
    }
  },

  async CreatePatient({ rootGetters }, data) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    try {
      const response = await axios.post("patienten/", data, config);
      return response;
    } catch (error) {
      throw "Some error";
    }
  },
};

const mutations = {
  setPatienten(state, data) {
    state.patienten = data;
  },

  clearPatienten(state) {
    state.patienten = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
