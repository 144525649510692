<template>
  <v-row justify="center">
    <v-dialog
      v-model="active"
      persistent
      max-width="600px"
      @keydown.esc="closeSelectHeilmittel"
    >
      <v-card>
        <v-card-title>
          <span>Heilmittelauswahl</span>
        </v-card-title>

        <v-card-text style="padding-bottom: 0px">
          <v-container>
            <v-row>
              <v-col style="padding-left: 0px; padding-right: 0px">
                <v-data-table
                  dense
                  show-select
                  v-model="behandlungen_selected"
                  :headers="behandlungen_headers"
                  :items="verbleibende_behandlungen"
                  :items-per-page="10"
                  class="elevation-1"
                  :hide-default-footer="true"
                  item-key="key"
                  @click:row="rowClicked"
                  @item-selected="checkSelection"
                >
                  <template v-slot:item.remaining="{ item }">
                    {{ item.remaining }} / {{ item.anzahl }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <p><b> Summe: </b>{{ time_behandlungen }}</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSelectHeilmittel">
            Zurück
          </v-btn>
          <v-btn
            :disabled="behandlungen_selected.length == 0"
            class="primary-nav-btn" 
            color="primary"
            text
            @click="hideAddTerminWithSnackbar"
            >Terminauswahl</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "SelectHeilmittel",

  data() {
    return {
      behandlungen_headers: [
        {
          text: "Bezeichnung",
          align: "start",
          value: "bezeichnung",
        },
        {
          text: "Verbleibend",
          value: "remaining",
        },
        {
          text: "Dauer",
          value: "zeiteinheit",
        },
      ],
    };
  },
  computed: {
    active() {
      return this.$store.getters["overlays/addSelectHeilmittelIsVisible"];
    },

    verbleibende_behandlungen() {
      return this.$store.getters["overlays/getRemainingBehandlungen"];
    },

    time_behandlungen() {
      return this.$store.getters["overlays/behandlungenSelectedLength"];
    },

    behandlungen_selected: {
      get() {
        return this.$store.state.overlays.add.selected.behandlungen_selected;
      },

      set(value) {
        this.$store.commit("overlays/updateBehandlungenSelected", value, {
          root: true,
        });
      },
    },
  },
  methods: {
    ...mapMutations("overlays", [
      "closeSelectHeilmittel",
      "hideAddTerminWithSnackbar",
    ]),

    rowClicked(d1, d2) {
      if (d2.isSelected) {
        d2.select(false);
      } else {
        d2.select(true);
      }
    },
    checkSelection(selection) {
      const { item, value } = selection;

      if (value) {
        // item was selected
        // check if there is another item remaining that has the same name but different key and add it also as selected
        const relevant_selected = this.verbleibende_behandlungen.filter(
          (beh) => beh.bezeichnung == item.bezeichnung
        );
        this.behandlungen_selected =
          this.behandlungen_selected.concat(relevant_selected);
      } else {
        // item was deselected, deselect any other item that has the same name
        this.behandlungen_selected = this.behandlungen_selected.filter(
          (beh) => beh.bezeichnung != item.bezeichnung
        );
      }
    },
  },
  watch: {},
};
</script>

<style></style>
