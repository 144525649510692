<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-card light>
        <v-card-title>
          <span>Termin abweisen</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols=12 class="pb-0">
                Bitte wählen Sie den Abweisungsgrund:
              </v-col>
            </v-row>

            <v-row style="text-align:center">
              <v-col cols=12>
                <v-select v-model="selected_item" :items="getValidItems" label="Abweisungsgrund" solo hide-details :disabled="getValidItems.length==1"></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols=12>
                <b>Beschreibung: </b>{{ items_description[selected_item] }}
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeWindow">Zurück</v-btn>

          <v-btn class="primary-nav-btn" color="primary" text @click="rejectTermin" :disabled="!selected_item">Ok</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>


    </v-dialog>





  </v-row>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";

const initialData = () => ({
  selected_item: null,
  items: ["Abgesagt", "Abgebrochen"],
  items_description: {
    "Abgesagt": "Der Termin wurde vom Patienten / von der Praxis abgesagt und wird an einem späteren Zeitpunkt nachgeholt.",
    "Abgebrochen": "Die Behadlung wird abgebrochen. Der aktuelle ausgewählte Termin und alle weiteren noch offene Termine werden abgesagt.",
  },
  isLoading: false,
  now: moment(),
});

export default {
  name: "TerminReject",

  data() {
    return initialData();
  },

  computed: {

    ...mapGetters("termine", ["getTermin"]),

    active() {
      return this.$store.getters["overlays/terminRejectActive"];
    },

    termin_id() {
      return this.$store.getters["overlays/terminRejectId"];
    },

    getValidItems() {
      let appointment = this.getTermin(this.termin_id);
      if (appointment){
        let start = moment(appointment.start);

        if (start.isBefore(this.now)) {
          return this.items.filter(item => item !== "Abgebrochen")
        }

      }
      return this.items;
    },

  },

  methods: {
    ...mapActions("overlays", ["closeTerminReject", "setTerminRejectId"]),
    ...mapActions("termine", ["updateTerminStatus"]),
    ...mapMutations("overlays", ["openExcessPaymentNotification"]),


    closeWindow() {
      this.closeTerminReject()
    },

    async rejectTermin() {

      this.isLoading = true

      let data = {
        "id": this.termin_id,
        "status": this.selected_item,
      }

      // update termin
      let response_data = await this.updateTerminStatus(data);

      // reset termin_id
      await this.setTerminRejectId(null);

      this.isLoading = false
      this.closeTerminReject()

      this.openExcessPaymentNotification(response_data.warnings);
    }

  },
  watch: {
    active(val) {
      if (val) {
        this.selected_item = null;
        this.now = moment();

        if (this.getValidItems.length === 1) {
          this.selected_item = this.getValidItems[0]
        }
      }
    }
  },

  mounted() { }
};
</script>

<style></style>