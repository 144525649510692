<template>
  <v-row>
    <v-dialog v-model="active" persistent max-width="850px">
      <v-card light>
        <v-card-title>
          <span>Heilmittel Nacherfassung</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container>
            <v-row class="pb-6">
              <div>
                Ein oder mehrere ausgewählte Heilmittel sind in der gewählten
                Kombination aus Rezeptart, Kostenträger und Diagnose ungültig.
                Bitte korrigieren Sie alle rot markierten Felder, indem Sie ein
                gültiges Heilmittel wählen.
              </div>
            </v-row>

            <div v-if="initActive">
              <v-row v-for="index in 2" :key="index">
                <v-col v-for="index in 2" :key="index">
                  <v-skeleton-loader class="input-skeleton"
                    type="list-item"
                    :loading="isLoading"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>

            <v-row v-for="(entry, index) in entries" :key="index" class="">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="entry.description"
                  label="Aktuelles Heilmittel"
                  outlined
                  dense
                  readonly
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="entry.time_unit"
                  :items="options"
                  item-text="description"
                  label="Neues Heilmittel"
                  outlined
                  dense
                  return-object
                  :error="!entry.time_unit"
                  hide-details
                  :disabled="isLoading"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeWindow"
            :disabled="isDisabled || isLoading"
            >Zurück</v-btn
          >
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="updateTarife"
            :disabled="isDisabled || isLoading || !allSelected"
            :loading="isLoading"
            >Aktualisieren</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapMutations } from "vuex/dist/vuex.common.js";

const initialData = () => ({
  isLoading: false,
  isDisabled: false,
  initActive: false,

  options: [],
  entries: [],
});

export default {
  name: "TarifeNacherfassung",

  data: function() {
    return initialData();
  },

  computed: {
    active() {
      return this.$store.getters["overlays/tarifeNacherfassungActive"];
    },

    groupId() {
      return this.$store.getters["overlays/tarifeNacherfassungGroupId"];
    },

    allSelected() {
      return this.entries.every((entry) => entry.time_unit);
    },
  },
  methods: {
    ...mapActions("nacherfassung", ["getNacherfassungTarife", "updateNacherfassungTarife"]),
    ...mapMutations("overlays", ["closeTarifeNacherfassung"]),

    closeWindow() {
      // Reset data
      Object.assign(this.$data, initialData());

      this.closeTarifeNacherfassung();
    },

    async updateTarife() {
      let data = {
        groupId: this.groupId,
        entries: this.entries,
      }

      this.isLoading = true;
      await this.updateNacherfassungTarife(data);
      this.closeTarifeNacherfassung();
      this.isLoading = false;

    },
  },

  watch: {
    async active(val) {
      if (val) {
        this.initActive = true;
        this.isLoading = true;

        let data = await this.getNacherfassungTarife(this.groupId);
        this.entries = data.entries;
        this.options = data.options;

        this.isLoading = false;
        this.initActive = false;
      }
    },
  },
};
</script>

<style>
.input-skeleton .v-skeleton-loader__list-item {
  height: 40px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.input-skeleton .v-skeleton-loader__text.v-skeleton-loader__bone{
  height: 40px !important;
}

</style>
