import axios from 'axios';

const state = {
    kunde: null,
};

const getters = {
    getKunde: state => {
        return state.kunde
    },
};

const actions = {
    async GetKundenData({ commit, rootGetters }) {
        let config = {
            headers: rootGetters['auth/authHeaders'],
        }

        return axios.get('kunden/', config)
            .then(res => {
                commit('setKundenData', res.data[0])
            }).catch(err => {
                console.log(err)
            })



    },

};

const mutations = {
    setKundenData(state, data) {
        state.kunde = data
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};