import axios from 'axios';

const getDefaultState = () => {
    return {
        versicherungsstatus: []
    }
}


// initial state
const state = getDefaultState()

const getters = {
    getVersicherungsStatus: state => {
        return state.versicherungsstatus
    }
};

const actions = {
    async GetVersicherungsStatusData({ commit, rootGetters }) {
        let bundesland = (['03', '12', '13', '14', '16'].indexOf(rootGetters['auth/currentUser'].tarif_region) != -1) ? "alt" : "neu"

        let config = {
            headers: rootGetters['auth/authHeaders'],
            params: {
                bundesland: bundesland
            }
        }

        return axios.get('versicherungsstatus/', config)
            .then(res => {
                commit('setVersicherungsStatusData', res.data)
            }).catch(err => {
                console.log(err)
            })


    },
};

const mutations = {

    setVersicherungsStatusData(state, data) {
        state.versicherungsstatus = data
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};