<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="1000px">
      <v-card light>
        <v-card-title>
          <span>Aktuelle Rezepte</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="pb-0" align="end">
              <v-col cols="3">
                <v-menu
                  v-model="menu.fromDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      ref="fromDate"
                      label="Rezeptdatum von"
                      prepend-icon="mdi-calendar-start"
                      v-model="selections.fromDate"
                      v-on="on"
                      @input="handleInputDateText('fromDate')"
                      @focus="handleFocusDateText('fromDate')"
                      placeholder="TT.MM.JJJJ"
                      hide-details
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.fromDate"
                    @input="handleInputDatePicker('fromDate')"
                    first-day-of-week="1"
                    locale="de-de"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="3">
                <v-menu
                  v-model="menu.toDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      ref="toDate"
                      label="Rezeptdatum bis"
                      prepend-icon="mdi-calendar-end"
                      v-model="selections.toDate"
                      v-on="on"
                      @input="handleInputDateText('toDate')"
                      @focus="handleFocusDateText('toDate')"
                      placeholder="TT.MM.JJJJ"
                      hide-details
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.toDate"
                    @input="handleInputDatePicker('toDate')"
                    first-day-of-week="1"
                    locale="de-de"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-select
                  ref="ktType"
                  v-model="filters.ktType"
                  :items="ktTypeOptions"
                  label="Kostenträger-Typ"
                  placeholder="Alle Kostenträger"
                  clearable
                  hide-details
                  prepend-icon="mdi-domain"
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="pt-0 mt-0" align="center">
              <v-col>
                <v-text-field
                  v-model="search"
                  class="pb-5"
                  append-icon="mdi-magnify"
                  label="Suche"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-btn
                  class="primary-nav-btn"
                  color="primary"
                  dense
                  small
                  @click="clearFilters"
                  >Zurücksetzen</v-btn
                >
              </v-col>
            </v-row>

            <v-data-table
              ref="mainTable"
              :loading="isLoading"
              :headers="headers"
              :items="items"
              :server-items-length="totalEntries"
              :options.sync="dataOptions"
              :footer-props="{
                itemsPerPageText: 'Einträge pro Seite',
                itemsPerPageAllText: 'Alle',
              }"
              loading-text="Daten werden geladen... Bitte warten"
              :single-expand="true"
              show-expand
              :expanded.sync="expanded"
              @pagination="paginationChanged"
              class="elevation-1"
            >
              <template #footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} von
                {{ props.itemsLength }}
              </template>

              <template slot="no-data">
                <div>Keine aktuellen Rezepte gefunden</div>
              </template>

              <template v-slot:item.rezeptdatum="{ item }">
                {{ item.rezeptdatum | formatDate }}
              </template>

              <template v-slot:item.vers_nummer="{ item }">
                <span v-if="item.vers_nummer">{{ item.vers_nummer }}</span>
                <span v-else>-</span>
              </template>

              <template v-slot:item.unfalltag="{ item }">
                <span v-if="item.unfalltag">{{
                  item.unfalltag | formatDate
                }}</span>
                <span v-else>-</span>
              </template>

              <template v-slot:item.kostentraeger_ik_num="{ item }">
                <span v-if="item.kostentraeger_ik_num">{{
                  item.kostentraeger_ik_num
                }}</span>
                <span v-else>-</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :disabled="isStringZero(item.zuzahlung)"
                      v-if="['KK', 'S'].includes(item.kostentraeger_typ)"
                      @click="handleGetQuittung(item)"
                    >
                      mdi-receipt
                    </v-icon>
                  </template>
                  <span v-if="item.quittung_typ == 'Rechnung'">ZUZ-Rechnung</span>
                  <span v-else>Quittung</span>
                </v-tooltip>

                <span v-if="!['KK', 'S'].includes(item.kostentraeger_typ)"
                  >-</span
                >
              </template>

              <template v-slot:expanded-item="{ item: parentItem }">
                <td :colspan="headers.length">
                  <div class="ma-4">
                    <div>
                      <strong>Behandlungen: </strong>
                      {{ getAppointmentsSummary(parentItem.appointments_data) }}
                    </div>
                    <div v-if="parentItem.bemerkung" class="pt-2">
                      <strong>Bemerkung: </strong>
                      {{ parentItem.bemerkung }}
                    </div>
                  </div>
                </td>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="closeWindow"
            >Zurück</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="quittungDialogActive" width="450">
      <v-card>
        <v-card-title>Quittung erstellen</v-card-title>
        <v-card-text>
          <div class="content">
            <v-row>
              <v-col>
                <v-select
                  v-model="selectedPaymentMethod"
                  :items="paymentMethods"
                  prepend-icon="mdi-hand-coin"
                  label="Zahlungsart"
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <strong>Hinweis: </strong>
                <span v-if="['Barzahlung', 'Kartenzahlung'].includes(selectedPaymentMethod)">Bei der ausgewählten Zahlungsart wird ein Eintrag im <strong>Kassenbuch</strong> erstellt.</span>
                <span v-else>Bei der ausgewählten Zahlungsart wird ein Eintrag in den <strong>Offenen Posten</strong> erstellt.</span>
              </v-col>
            </v-row>


          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeQuittungDialog"
            :disabled="isDisabled"
            >Zurück</v-btn
          >
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="getQuittung(selectedHeaderId, selectedPaymentMethod)"
            :loading="isDisabled"
            :disabled="isDisabled"
            >Erstellen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-row>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";

const defaultSelectionsData = () => ({
  fromDate: null,
  toDate: null,
});

const defaultFiltersData = () => ({
  fromDate: null,
  toDate: null,
  headerId: null,
  ktType: null,
});

const defaultMenuData = () => ({
  fromDate: false,
  toDate: false,
});

const initialData = () => ({
  componentName: "PrescriptionOverview",
  headers: [
    {
      text: "Rezeptdatum",
      value: "rezeptdatum",
      sortable: true,
      align: "start",
    },
    {
      text: "KT-Typ",
      value: "kostentraeger_typ",
      sortable: true,
    },
    {
      text: "Name",
      value: "name",
      sortable: true,
    },
    {
      text: "Vorname",
      value: "vorname",
      sortable: true,
    },
    {
      text: "Vers.-Nr.",
      value: "vers_nummer",
      sortable: true,
    },
    {
      text: "Unfalltag",
      value: "unfalltag",
      sortable: true,
    },
    {
      text: "",
      value: "data-table-expand",
      sortable: false,
    },
    {
      text: "Aktionen",
      value: "actions",
      sortable: false,
      align: "center",
    },
  ],

  search: "",

  expanded: [],

  dataOptions: {
    sortBy: ["name"],
    sortDesc: [false],
    page: 1,
    itemsPerPage: 5,
    multiSort: true,
  },

  items: [],
  totalEntries: 0,

  initLoad: false,
  isLoading: false,
  isDisabled: false,



  ktTypeOptions: [
    { text: "Berufsgenossenschaft", value: "BG" },
    { text: "Krankenkassse", value: "KK" },
    { text: "Postbeamtenkrankenkasse", value: "PBeaKK" },
    { text: "Privat", value: "PP" },
    { text: "Sonstige", value: "S" },
  ],

  quittungDialogActive: false,
  selectedHeaderId: null,
  selectedPaymentMethod: "Barzahlung",
  paymentMethods: [
    "Barzahlung",
    "Kartenzahlung",
    "Überweisung",
  ],

  filters: defaultFiltersData(),
  selections: defaultSelectionsData(),
  menu: defaultMenuData(),
});

export default {
  name: "PrescriptionOverview",

  data() {
    return initialData();
  },

  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(String(value)).format("DD.MM.YYYY");
    },
  },

  computed: {
    active() {
      return this.$store.getters["overlays/prescriptionOverviewActive"];
    },
  },

  methods: {
    ...mapMutations("overlays", ["closePrescriptionOverview"]),
    ...mapActions("prescriptions", ["queryPartialPrescriptions", "fetchQuittungPDF"]),

    isStringZero(value) {
      return value === "0" || value === "0.00";
    },

    getOrder() {
      // iterate over sortBy and sortDesc e.g.
      // sortBy: ["start", "end"],
      // sortDesc: [false, true]
      // and return "start,-end"

      let order = [];
      for (let i = 0; i < this.dataOptions.sortBy.length; i++) {
        let desc = this.dataOptions.sortDesc[i] ? "-" : "";
        let name = this.dataOptions.sortBy[i];

        order.push(desc + name);
      }
      return order.join(",");
    },

    clearFilters() {
      this.search = "";

      this.filters = defaultFiltersData();
      this.selections = defaultSelectionsData();
      this.menu = defaultMenuData();
    },

    clearSelections() {
      // Close any expanded items
      this.expanded = [];
    },

    paginationChanged() {
      this.clearSelections();
    },

    getAppointmentsSummary(data) {
      let parts = [];
      data.behandlungen.forEach((item) => {
        let text = item.count + "x " + item.name;
        parts.push(text);
      });
      return parts.join(" / ");
    },

    closeWindow() {
      // reset data
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));

      this.closePrescriptionOverview();
    },

    async getData() {
      let params = {
        page: this.dataOptions.page,
        pageSize: this.dataOptions.itemsPerPage,
      };

      if (this.dataOptions.sortBy) {
        params["orderBy"] = this.getOrder();
      }

      if (this.search) {
        params["search"] = this.search;
      }

      if (this.filters.ktType) {
        params["ktType"] = this.filters.ktType;
      }

      if (this.filters.fromDate) {
        params["fromDate"] = this.filters.fromDate;
      }

      if (this.filters.toDate) {
        params["toDate"] = this.filters.toDate;
      }

      if (this.filters.headerId) {
        params["headerId"] = this.filters.headerId;
      }

      this.isLoading = true;

      let data = {};
      try {
        data = await this.queryPartialPrescriptions(params);
      } catch (e) {
        if (e.response.status == 400) {
          this.dataOptions.page = 1;
          data = await this.queryPartialPrescriptions(params);
        }
      }

      this.items = data.results;
      this.totalEntries = data.totalEntries;
      this.isLoading = false;
    },

    handleInputDateText(name) {
      if (this.selections[name]) {
        let m = moment(this.selections[name], "DD.MM.YYYY", true);
        if (m.isValid()) {
          this.filters[name] = m.format("YYYY-MM-DD");

          setTimeout(() => {
            this.menu[name] = false;
          }, 200);
        } else {
          this.filters[name] = null;
        }
      } else {
        this.filters[name] = null;
      }
    },

    handleFocusDateText(name) {
      var menu_names = ["fromDate", "toDate"].filter((e) => e !== name);

      setTimeout(() => {
        if (!this.menu[name]) {
          this.menu[name] = true;
        }
        for (const n of menu_names) {
          this.menu[n] = false;
        }
      }, 200);
    },

    handleInputDatePicker(name) {
      // set selection
      this.selections[name] = moment(this.filters[name]).format("DD.MM.YYYY");

      // close picker
      this.menu[name] = false;
    },


    openQuittungDialog(headerId) {
      this.selectedHeaderId = headerId;
      this.quittungDialogActive = true;
    },

    closeQuittungDialog() {
      this.quittungDialogActive = false;
      this.selectedHeaderId = null;
    },


    async handleGetQuittung(header){
      if (header.quittung){
        this.isLoading = true;
        await this.fetchQuittungPDF({headerId: header.id})
        this.isLoading = false;
      }
      else {
        this.openQuittungDialog(header.id);
      }
    },

    async getQuittung(headerId, paymentMethod=null){
      this.isDisabled = true;
      await this.fetchQuittungPDF({headerId: headerId, paymentMethod: paymentMethod});
      this.closeQuittungDialog();
      this.isDisabled = false;
    },

  },
  watch: {
    async active(val) {
      if (val && this.items.length == 0) {
        await this.getData();
        this.initLoad = true;
      }
    },

    async quittungDialogActive(val) {
      if (!val && this.active) {
        this.dataOptions.page = 1;
        await this.getData();
      }
    },

    async search() {
      if (this.active) {
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(async () => {
          this.dataOptions.page = 1;
          await this.getData();
        }, 500);
      }
    },

    "filters.ktType": async function () {
      if (this.active) {
        this.dataOptions.page = 1;
        await this.getData();
      }
    },

    "filters.fromDate": async function () {
      if (this.active) {
        this.dataOptions.page = 1;
        await this.getData();
      }
    },

    "filters.toDate": async function () {
      if (this.active) {
        this.dataOptions.page = 1;
        await this.getData();
      }
    },

    async dataOptions() {
      if (this.active && this.initLoad) {
        await this.getData();
      }
    },
  },

  mounted() {},
};
</script>

<style>
.v-input--selection-controls__ripple {
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  position: absolute;
  transition: inherit;
  width: 22px;
  left: -6px;
  top: calc(50% - 18px);
  margin: 7px;
}
</style>
