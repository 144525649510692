import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Main from "../views/Main.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isAuthenticated"]) {
      if (store.getters["auth/isTokenValid"]) {
        next();
        return;
      }
    }
    store.commit("auth/logout");
    // Only redirect to login if not already there
    if (from.path !== "/login") {
      next("/login");
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters["auth/isAuthenticated"]) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
