import Vue from "vue";
const state = {
  drawer: {
    status: false,
  },
  calendar: {
    type: "day",
    categories: [],
  },
};

const getters = {
  getDrawerStatus: (state) => {
    return state.drawer.status;
  },

  getCalendarType: (state) => {
    return state.calendar.type;
  },

  getCalendarCategories: (state) => {
    return state.calendar.categories;
  },
};

const actions = {
  fixCalendarCategories({ commit, state }, mitarbeiter) {
    let saved_categories = new Set(state.calendar.categories);
    let available_categories = mitarbeiter.map((m) => m.name + ", " + m.vorname);
    let intersection = available_categories.filter((x) => saved_categories.has(x));

    commit("setCalendarCategories", intersection);
  },
};

const mutations = {
  setDrawerStatus(state, data) {
    state.drawer.status = data;
  },

  setCalendarType: (state, data) => {
    state.calendar.type = data;
  },

  setCalendarCategories: (state, items) => {
    Vue.set(state.calendar, "categories", [...items]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
