<template>
  <v-main>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-spacer></v-spacer>
        <v-col :cols="12" :sm="10" :md="6" :lg="4">
          <v-card class="elevation-12">
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-img contain max-height="30" max-width="250" src="@/assets/logo.png"></v-img>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-form @submit.prevent="submit">
              <v-card-text>
                <v-text-field
                  color="#AE2855"
                  prepend-icon="mdi-account"
                  name="username"
                  label="Benutzername"
                  type="text"
                  v-model="form.username"
                  @input="clearErrors"
                  :error="loginErrors.length != 0"
                ></v-text-field>
                <v-text-field
                  color="#AE2855"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Passwort"
                  type="password"
                  v-model="form.password"
                  @input="clearErrors"
                  :error-messages="loginErrors"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#AE2855" type="submit" class="white--text">Anmelden</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>

    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  metaInfo: {
    title: "medisum Termine - Login",
  },
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      loginErrors: [],
      isLoading: false,
    };
  },
  methods: {
    // map `this.LogIn()` auth LogIn action
    ...mapActions("auth", ["LogIn"]),

    clearErrors() {
      this.loginErrors = [];
    },

    async submit() {
      let user = {
        username: this.form.username,
        password: this.form.password,
      };

      try {
        this.isLoading = true;
        await this.LogIn(user);
        this.$router.push("/");
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.loginErrors.push(error.msg);
      }
    },
  },
};
</script>

<style></style>
