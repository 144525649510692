import axios from 'axios';

const state = {
    diagnosen: []
};

const getters = {
    getDiagnosen: state => {
        return state.diagnosen
    }
};

const actions = {
    async Search({commit, rootGetters}, params) {
        if (params.term.length >= 2){
            let config = {
                headers: rootGetters['auth/authHeaders'],
                params: {
                    search: params.term,
                    bereich: rootGetters["kunde/getKunde"].heilmittelbereich,
                    rezeptart: params.rezeptart,
                }
            }
            const response = await axios.get('diagnose/', config)
            await commit('setDiagnosen', response.data)
        }

      },
      
};
 
const mutations = {

    setDiagnosen(state, data){
        state.diagnosen = data
    },

    clearDiagnosen(state){
        state.diagnosen = []
    }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};